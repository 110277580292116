import React from "react";

type P = {
    title: string
    items: {
        name: string
        link: string
        image: string
    }[]
};

export type LayoutProps = P

export const Layout = ({ title, items }: P) => {
    return (
        <div className='flex flex-col gap-4 items-center justify-center'>
            <span className="tracking-wider text-gray-500 uppercase">
                {title}
            </span>

            <div className="flex flex-wrap w-[100%] gap-4 items-center justify-center">
                {items.map(item => {
                    return (
                        <a
                            key={item.name}
                            href={item.link}
                            target="_blank"
                            rel="noreferrer"
                            className="shadow-lg rounded-lg sm:w-72 h-36 px-10 py-4 flex flex-col"
                        >
                            <img
                                src={item.image}
                                alt={item.name}
                                className="w-full h-full object-contain"
                            />
                        </a>
                    )
                })}
            </div>
        </div>
    )
};
