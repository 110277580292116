import React from "react"
import { graphql } from "gatsby"
import { Container } from "components/library"
import { CtaBlock } from "components/common/cta-block"
import { Hero } from "components/pages/about-us/hero"
import { WhatWeDo } from "components/pages/about-us/what-we-do"
import { AcrossTheGlobe } from "components/pages/about-us/across-the-globe"
import { InvestorsNPartners } from "components/pages/about-us/investors-n-partners"
import { MeetTheTeam } from "components/pages/about-us/meet-the-team"
// import { useTranslation } from "react-i18next"
// import TeamCarousel from "components/pages/about-us/team-carousel"
import CompanyProfile from "components/pages/about-us/company-profile"


const AboutUs = () => {
	// const { t } = useTranslation()
	return (
		<main>
			<Hero />

			<Container className="flex flex-col gap-32 sm:gap-20 py-24">
				<WhatWeDo />
			</Container>

			<AcrossTheGlobe />

			<Container className="flex flex-col gap-32 sm:gap-20 py-24">
				<InvestorsNPartners />
				<div className={`flex flex-col justify-center md:flex-none md:grid md:gap-10 md:w-7/8 mx-auto ${process.env.GATSBY_LANGUAGE_SELECTED === "ja" ? "md:grid-cols-2" : "md:grid-cols-1"}`}>
					<MeetTheTeam />
					{process.env.GATSBY_LANGUAGE_SELECTED === "ja" && <CompanyProfile />}
				</div>
{/* 				<TeamCarousel /> */}
			</Container>

			<CtaBlock />
		</main>
	)
}

export default AboutUs

// FOR LANGUAGE TRANSLATION
export const query = graphql`
	query ($language: String!) {
		locales: allLocale(filter: { language: { eq: $language } }) {
			edges {
				node {
					ns
					data
					language
				}
			}
		}
	}
`
