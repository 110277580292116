import React, { useState } from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Layout, LayoutProps } from "./meet-the-team-layout"
import Kunal from "images/team/Kunal.jpeg"
import Abhijeet from "images/team/Abhijit.jpeg"
import Premnath from "images/team/dr-premanth.jpeg"
import DrSubbu from "images/team/dr-subbu.png"
import TeckMoh from "images/team/teck-moh-phey.jpeg"
import { useTranslation } from "gatsby-plugin-react-i18next"

const advisors: LayoutProps["people"] = [
	{
		name: "Dr. Premnath Venugopalan",
		social: "https://www.linkedin.com/in/premnathv",
		image: Premnath,
	},
	{
		name: "Dr. Subbu S. Venkatraman",
		social: "https://www.linkedin.com/in/subbu-venkatraman-471743",
		image: DrSubbu,
	},
	{
		name: "Teck Moh Phey",
		social: "https://www.linkedin.com/in/teck-moh-phey-99904a2",
		image: TeckMoh,
	},
]

export const MeetTheTeam = () => {
	const { t } = useTranslation();
	const [leadership] = useState([
		{
			name: "Kunal Sandeep",
			designation: t("about-us-page.company-singapore.ceo.label"),
			image: Kunal,
			social: "https://www.linkedin.com/in/kunalsandeep/",
		},
		{
			name: "Abhijit Salvekar",
			designation: t("about-us-page.company-singapore.cto.label"),
			image: Abhijeet,
			social: "https://www.linkedin.com/in/abhijitvijaysalvekar/",
		},
	]);

	return (
		<section className="text-center flex flex-col gap-12 border border-fade-gradient rounded-3xl p-8">
			<div className="flex flex-col gap-4 items-center">
				<StaticImage
					src="../../../images/icon_team.svg"
					className="w-20 h-20"
					alt="meet the team"
				/>
				<p className="tracking-wider text-gray-500 uppercase">
					{t("about-us-page.meet-the-team.label")}
				</p>
				<p className="text-3xl tracking-tight max-w-sm">
					{t("about-us-page.meet-the-team.heading")}
				</p>
			</div>

			<Layout title={t("common.words.our-leadership")} people={leadership} />
			<Layout title={t("common.words.our-advisors")} people={advisors} />
		</section>
	)
}
