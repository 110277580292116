import React from "react"
import { Container} from "components/library"
import { useTranslation } from "gatsby-plugin-react-i18next"
import { AboutUsIllustration } from "components/library/illustrations/about-us-illustration";

export const Hero = () => {
	const { t } = useTranslation()

	return (
		<section className="text-center flex flex-col gap-24 pt-24 df-sm:gap-12">
			<Container>
				<h1 className="text-4xl tracking-wider sm:text-5xl md:text-7xl">
					{t("about-us-page.hero.heading")}
				</h1>
			</Container>

			<AboutUsIllustration className="w-full" />
		</section>
	)
}
