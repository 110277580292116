import React from "react"
import { Container } from "components/library"
import { useI18next } from "gatsby-plugin-react-i18next"
import {
	AcrossTheGlobeIllustrationJapanese
} from "components/library/illustrations/across-the-globe-illustration-japanese";
import { AcrossTheGlobeIllustrationMob } from "components/library/illustrations/across-the-globe-illustration-mob";
import {
	AcrossTheGlobeIllustrationMobJapanese
} from "components/library/illustrations/across-the-globe-illustration-mob-japanese";
import AcrossTheGlobeIllustration from "components/library/illustrations/across-the-globe-illustration";


export const AcrossTheGlobe = () => {
	const { language } = useI18next()

	return (
		<div>
			<Container className="hidden h-auto sm:block">
				{language === "ja" ? (
					<AcrossTheGlobeIllustrationJapanese className="w-full" />
				) : (
					<AcrossTheGlobeIllustration className="w-full" />
				)}
			</Container>

			<section className="sm:hidden">
				{language === "ja" ? (
					<AcrossTheGlobeIllustrationMobJapanese className="w-full" />
				) : (
					<AcrossTheGlobeIllustrationMob className="w-full" />
				)}
			</section>
		</div>
	)
}
