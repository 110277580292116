import React from "react";
import { StaticImage } from 'gatsby-plugin-image';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { ResearchCycle } from "components/common/research-cycle/research-cycle";

export const WhatWeDo = () => {
    const { t } = useTranslation()

    return (
        <section className="flex flex-col gap-8 sm:gap-16 items-center">
            <div className='flex flex-col gap-6 items-start w-full sm:items-center'>
                <StaticImage
                    src="../../../images/icon_what_we_do.svg"
                    alt="what we do"
                />
                <p className="tracking-wider text-gray-500 uppercase">
                    {t('about-us-page.what-we-do.label')}
                </p>
            </div>

            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2">
                <div className="flex flex-col gap-4">
                    <h2 className="text-4xl tracking-tight sm:text-5xl">
                        {t('about-us-page.what-we-do.works.1.heading')}
                    </h2>
                    <p className="sm:text-lg md:text-xl">
                        {t('about-us-page.what-we-do.works.1.desc')}
                    </p>
                </div>

                <div className="flex flex-col gap-4">
                    <h2 className="text-4xl tracking-tight sm:text-5xl">
                        {t('about-us-page.what-we-do.works.2.heading')}
                    </h2>
                    <p className="sm:text-lg md:text-xl">
                        {t('about-us-page.what-we-do.works.2.desc')}
                    </p>
                </div>
            </div>

            <ResearchCycle />
        </section>
    )
}
