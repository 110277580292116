import React from "react";
import { Linkedin } from 'react-feather';

type P = {
    title: string
    people: {
        name: string
        designation?: string
        image: string
        social: string
    }[]
};

export type LayoutProps = P

export const Layout = ({ title, people }: P) => {
    return (
        <div className='flex flex-col gap-10'>
            <p className="text-2xl">
                {title}
            </p>

            <div className="flex-wrap flex gap-4 justify-center">
                {people.map(p => {
                    return (
                        <a
                            key={p.name}
                            href={p.social}
                            target="_blank"
                            rel="noreferrer"
                            className="text-center w-32 cursor-pointer group flex flex-col gap-6"
                        >
                            <div className="shadow-lg relative h-32 rounded-xl overflow-hidden">
                                <img
                                    src={p.image}
                                    className="w-full"
                                    alt={p.name}
                                />
                                <div
                                    className="text-white absolute inset-0 opacity-0 group-hover:opacity-80 bg-gradient-to-b from-theme-blue w-full h-full flex flex-col justify-center items-center"
                                >
                                    <Linkedin />
                                </div>
                            </div>

                            <div className='flex flex-col gap-1'>
                                <span className='font-semibold'>
                                    {p.name}
                                </span>
                                <span className="text-sm">
                                    {p.designation}
                                </span>
                            </div>
                        </a>
                    )
                })}
            </div>
        </div>
    )
};
