import React from "react"
import { Illustration } from "types/components"
import { mergeClasses } from "utils"

export const AboutUsIllustration: Illustration = ({
	className = "",
	...props
}) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 1440 278"
			className={mergeClasses({ classes: ["w-40", className] })}
			{...props}
		>
			<g opacity="0.4">
				<rect
					width="119"
					height="59"
					x="663.5"
					y="2.5"
					fill="#fff"
					stroke="#DFE1E6"
					rx="3.5"
				></rect>
				<path
					stroke="#3C67F0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M765.926 26.698l-10.079 10.08-4.582-4.582"
				></path>
				<path
					fill="#DFE1E6"
					d="M677.717 16.465h32.988v26.82a8 8 0 01-8 8h-16.988a8 8 0 01-8-8v-26.82z"
				></path>
				<path
					fill="#505F79"
					d="M686.881 22.088a.125.125 0 00.125-.125v-1.125a.125.125 0 00-.25 0v1h-1a.125.125 0 000 .25h1.125zm-1.921-1.869l1.832 1.833.177-.177-1.833-1.833-.176.177z"
				></path>
				<circle cx="685.047" cy="20.131" r="0.916" fill="#5F85EB"></circle>
				<path
					fill="#505F79"
					d="M688.061 24.174c.01.068.073.116.142.106l1.113-.159a.126.126 0 00.107-.141.126.126 0 00-.142-.106l-.99.14-.141-.99a.125.125 0 00-.247.036l.158 1.114zm1.58-2.166l-1.556 2.073.2.15 1.556-2.073-.2-.15z"
				></path>
				<circle
					cx="689.741"
					cy="22.083"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(81.888 689.741 22.083)"
				></circle>
				<path
					fill="#505F79"
					d="M702.895 23.33a.124.124 0 00-.087-.154l-1.084-.3a.125.125 0 10-.067.24l.964.267-.267.964a.125.125 0 00.088.154.124.124 0 00.153-.087l.3-1.085zm-2.313 1.353l2.254-1.278-.123-.218-2.255 1.278.124.218z"
				></path>
				<circle
					cx="700.52"
					cy="24.574"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-74.541 700.52 24.574)"
				></circle>
				<path
					fill="#505F79"
					d="M705.96 19.064a.124.124 0 00-.087-.153l-1.084-.3a.125.125 0 10-.067.24l.964.267-.267.964a.125.125 0 10.241.067l.3-1.085zm-2.314 1.354l2.255-1.278-.123-.218-2.255 1.278.123.218z"
				></path>
				<circle
					cx="703.585"
					cy="20.309"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-74.541 703.585 20.309)"
				></circle>
				<path
					fill="#505F79"
					d="M696.382 18.47a.124.124 0 00-.126.123l-.017 1.125a.125.125 0 10.25.003l.015-1 1 .015a.125.125 0 00.003-.25l-1.125-.016zm1.894 1.897l-1.806-1.86-.179.175 1.805 1.86.18-.175z"
				></path>
				<circle
					cx="698.186"
					cy="20.454"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-179.156 698.186 20.454)"
				></circle>
				<path
					fill="#505F79"
					d="M680.81 24.788a.124.124 0 00.052.17l.994.525a.125.125 0 10.117-.22l-.884-.468.467-.884a.125.125 0 00-.221-.117l-.525.994zm2.55-.824l-2.477.763.074.24 2.477-.764-.074-.239z"
				></path>
				<circle
					cx="683.396"
					cy="24.084"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(117.875 683.396 24.084)"
				></circle>
				<path
					fill="#505F79"
					d="M694.754 21.856a.125.125 0 00-.171.044l-.571.97a.125.125 0 10.215.126l.508-.861.861.507c.06.035.136.016.171-.044a.124.124 0 00-.044-.17l-.969-.572zm.706 2.586l-.648-2.51-.243.063.649 2.51.242-.063z"
				></path>
				<circle
					cx="695.339"
					cy="24.473"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-149.484 695.339 24.473)"
				></circle>
				<path
					fill="#505F79"
					d="M708.994 22.874a.125.125 0 00-.13-.12l-1.124.05a.125.125 0 00-.119.13.125.125 0 00.13.12l.999-.044.043.999a.126.126 0 00.131.12.125.125 0 00.119-.13l-.049-1.125zm-1.784 2l1.752-1.91-.185-.169-1.751 1.91.184.17z"
				></path>
				<circle
					cx="707.119"
					cy="24.79"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-92.485 707.119 24.79)"
				></circle>
				<path
					fill="#505F79"
					d="M681.426 18.662a.125.125 0 00-.174-.03l-.923.642a.124.124 0 00-.031.174c.039.057.117.071.174.032l.82-.572.572.82a.125.125 0 10.205-.143l-.643-.923zm-.435 2.645l.455-2.551-.246-.044-.455 2.551.246.044z"
				></path>
				<circle
					cx="680.869"
					cy="21.285"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-124.877 680.869 21.285)"
				></circle>
				<path
					fill="#5F85EB"
					d="M678.634 26.545h31.155V42.37a8 8 0 01-8 8h-15.155a8 8 0 01-8-8V26.545z"
				></path>
				<path
					fill="#DFE1E6"
					d="M676.8 12.8h34.82v1.498a4 4 0 01-4 4H680.8a4 4 0 01-4-4V12.8z"
				></path>
				<circle cx="693.296" cy="44.261" r="0.611" fill="#fff"></circle>
				<circle cx="690.241" cy="43.039" r="0.611" fill="#fff"></circle>
				<circle cx="693.296" cy="40.595" r="0.611" fill="#fff"></circle>
				<circle cx="691.463" cy="38.763" r="0.611" fill="#fff"></circle>
				<circle cx="693.906" cy="36.93" r="0.611" fill="#fff"></circle>
				<circle cx="692.073" cy="33.876" r="0.611" fill="#fff"></circle>
				<circle cx="695.127" cy="30.821" r="0.611" fill="#fff"></circle>
				<circle cx="697.571" cy="35.097" r="0.611" fill="#fff"></circle>
				<circle cx="695.739" cy="39.985" r="0.611" fill="#fff"></circle>
				<circle cx="696.96" cy="44.261" r="0.611" fill="#fff"></circle>
				<circle cx="698.793" cy="41.207" r="0.611" fill="#fff"></circle>
			</g>
			<g opacity="0.4">
				<rect
					width="119"
					height="59"
					x="988.699"
					y="68.5"
					fill="#fff"
					stroke="#DFE1E6"
					rx="3.5"
				></rect>
				<path
					stroke="#3C67F0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M1091.13 92.698l-10.08 10.08-4.59-4.582"
				></path>
				<path
					fill="#DFE1E6"
					d="M1002.92 82.466h32.98v26.82a8 8 0 01-8 8h-16.98a8 8 0 01-8-8v-26.82z"
				></path>
				<path
					fill="#505F79"
					d="M1012.08 88.088c.07 0 .12-.056.12-.125v-1.125c0-.069-.05-.125-.12-.125-.07 0-.13.056-.13.125v1h-1c-.07 0-.12.056-.12.125 0 .07.05.125.12.125h1.13zm-1.92-1.869l1.83 1.833.18-.177-1.84-1.833-.17.177z"
				></path>
				<circle cx="1010.25" cy="86.131" r="0.916" fill="#5F85EB"></circle>
				<path
					fill="#505F79"
					d="M1013.26 90.174c.01.068.07.116.14.106l1.11-.159c.07-.01.12-.073.11-.141a.123.123 0 00-.14-.106l-.99.14-.14-.99a.129.129 0 00-.14-.105.127.127 0 00-.11.141l.16 1.114zm1.58-2.166l-1.56 2.073.2.15 1.56-2.073-.2-.15z"
				></path>
				<circle
					cx="1014.94"
					cy="88.083"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(81.888 1014.94 88.083)"
				></circle>
				<path
					fill="#505F79"
					d="M1028.09 89.33a.125.125 0 00-.08-.154l-1.09-.3c-.07-.019-.13.02-.15.087-.02.066.02.135.08.154l.97.266-.27.964c-.02.067.02.135.09.154.07.018.13-.02.15-.087l.3-1.085zm-2.31 1.353l2.25-1.278-.12-.218-2.25 1.278.12.218z"
				></path>
				<circle
					cx="1025.72"
					cy="90.574"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-74.541 1025.72 90.574)"
				></circle>
				<path
					fill="#505F79"
					d="M1031.16 85.064a.123.123 0 00-.09-.153l-1.08-.3a.13.13 0 00-.16.087c-.01.066.03.135.09.154l.97.266-.27.964c-.02.067.02.135.09.154.06.018.13-.02.15-.087l.3-1.085zm-2.31 1.354l2.25-1.278-.12-.218-2.26 1.278.13.218z"
				></path>
				<circle
					cx="1028.78"
					cy="86.309"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-74.541 1028.78 86.309)"
				></circle>
				<path
					fill="#505F79"
					d="M1021.58 84.47c-.07-.001-.12.054-.12.123l-.02 1.125c0 .069.05.126.12.127.07 0 .13-.055.13-.123l.01-1 1 .014c.07.001.13-.054.13-.123s-.05-.126-.12-.127l-1.13-.016zm1.9 1.897l-1.81-1.86-.18.175 1.81 1.86.18-.175z"
				></path>
				<circle
					cx="1023.38"
					cy="86.454"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-179.156 1023.38 86.454)"
				></circle>
				<path
					fill="#505F79"
					d="M1006.01 90.788c-.04.061-.01.137.05.17l.99.525c.06.033.14.01.17-.052.04-.06.01-.136-.05-.169l-.88-.467.46-.884c.04-.061.01-.137-.05-.17-.06-.031-.13-.008-.17.053l-.52.994zm2.55-.824l-2.48.763.07.24 2.48-.764-.07-.239z"
				></path>
				<circle
					cx="1008.59"
					cy="90.084"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(117.875 1008.59 90.084)"
				></circle>
				<path
					fill="#505F79"
					d="M1019.95 87.856c-.06-.035-.13-.015-.17.044l-.57.97a.12.12 0 00.04.17c.06.035.14.016.17-.044l.51-.861.86.507c.06.035.14.016.18-.044a.131.131 0 00-.05-.171l-.97-.571zm.71 2.586l-.65-2.51-.24.063.65 2.51.24-.063z"
				></path>
				<circle
					cx="1020.54"
					cy="90.473"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-149.484 1020.54 90.473)"
				></circle>
				<path
					fill="#505F79"
					d="M1034.19 88.874a.122.122 0 00-.13-.12l-1.12.05a.127.127 0 00-.12.13c0 .069.06.122.13.12l1-.044.04.999c.01.069.06.122.13.12.07-.004.13-.062.12-.13l-.05-1.125zm-1.78 2l1.75-1.91-.18-.169-1.75 1.91.18.17z"
				></path>
				<circle
					cx="1032.32"
					cy="90.79"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-92.485 1032.32 90.79)"
				></circle>
				<path
					fill="#505F79"
					d="M1006.63 84.662a.132.132 0 00-.18-.03l-.92.642a.121.121 0 00-.03.174c.04.057.11.071.17.032l.82-.572.57.82c.04.057.12.07.18.031.05-.04.07-.117.03-.174l-.64-.923zm-.44 2.645l.46-2.551-.25-.044-.46 2.551.25.044z"
				></path>
				<circle
					cx="1006.07"
					cy="87.285"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-124.877 1006.07 87.285)"
				></circle>
				<path
					fill="#5F85EB"
					d="M1003.83 92.545h31.16v15.824c0 4.419-3.58 8-8 8h-15.16c-4.42 0-8-3.581-8-8V92.545z"
				></path>
				<path
					fill="#DFE1E6"
					d="M1002 78.8h34.82v1.498a4 4 0 01-4 4H1006a4 4 0 01-4-4V78.8z"
				></path>
				<circle cx="1018.49" cy="110.26" r="0.611" fill="#fff"></circle>
				<circle cx="1015.44" cy="109.039" r="0.611" fill="#fff"></circle>
				<circle cx="1018.49" cy="106.595" r="0.611" fill="#fff"></circle>
				<circle cx="1016.66" cy="104.762" r="0.611" fill="#fff"></circle>
				<circle cx="1019.1" cy="102.93" r="0.611" fill="#fff"></circle>
				<circle cx="1017.27" cy="99.876" r="0.611" fill="#fff"></circle>
				<circle cx="1020.33" cy="96.821" r="0.611" fill="#fff"></circle>
				<circle cx="1022.77" cy="101.097" r="0.611" fill="#fff"></circle>
				<circle cx="1020.94" cy="105.984" r="0.611" fill="#fff"></circle>
				<circle cx="1022.16" cy="110.26" r="0.611" fill="#fff"></circle>
				<circle cx="1023.99" cy="107.206" r="0.611" fill="#fff"></circle>
			</g>
			<g opacity="0.4">
				<rect
					width="119"
					height="59"
					x="1041.9"
					y="2.5"
					fill="#fff"
					stroke="#DFE1E6"
					rx="3.5"
				></rect>
				<path
					stroke="#3C67F0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M1144.32 26.698l-10.07 10.08-4.59-4.582"
				></path>
				<path
					fill="#DFE1E6"
					d="M1056.11 16.465h32.99v26.82c0 4.42-3.58 8-8 8h-16.99c-4.41 0-8-3.58-8-8v-26.82z"
				></path>
				<path
					fill="#505F79"
					d="M1065.28 22.088c.07 0 .12-.056.12-.125v-1.125c0-.069-.05-.125-.12-.125-.07 0-.13.056-.13.125v1h-1c-.07 0-.12.056-.12.125 0 .07.05.125.12.125h1.13zm-1.92-1.869l1.83 1.833.18-.177-1.84-1.833-.17.177z"
				></path>
				<circle cx="1063.44" cy="20.131" r="0.916" fill="#5F85EB"></circle>
				<path
					fill="#505F79"
					d="M1066.46 24.174c.01.068.07.116.14.106l1.11-.159c.07-.01.12-.073.11-.141a.123.123 0 00-.14-.106l-.99.14-.14-.99a.129.129 0 00-.14-.105.127.127 0 00-.11.141l.16 1.114zm1.58-2.166l-1.56 2.073.2.15 1.56-2.073-.2-.15z"
				></path>
				<circle
					cx="1068.14"
					cy="22.083"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(81.888 1068.14 22.083)"
				></circle>
				<path
					fill="#505F79"
					d="M1081.29 23.33a.125.125 0 00-.08-.154l-1.09-.3c-.07-.019-.13.02-.15.087-.02.066.02.135.08.154l.97.266-.27.964c-.02.066.02.135.09.154.07.018.13-.02.15-.087l.3-1.085zm-2.31 1.353l2.25-1.278-.12-.218-2.25 1.278.12.218z"
				></path>
				<circle
					cx="1078.92"
					cy="24.574"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-74.541 1078.92 24.574)"
				></circle>
				<path
					fill="#505F79"
					d="M1084.36 19.064a.123.123 0 00-.09-.153l-1.08-.3a.13.13 0 00-.16.087c-.01.066.02.135.09.154l.96.266-.26.964c-.02.067.02.135.09.154.06.018.13-.02.15-.087l.3-1.085zm-2.31 1.354l2.25-1.278-.12-.218-2.26 1.278.13.218z"
				></path>
				<circle
					cx="1081.98"
					cy="20.309"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-74.541 1081.98 20.309)"
				></circle>
				<path
					fill="#505F79"
					d="M1074.78 18.47c-.07-.001-.12.054-.13.123l-.01 1.125c0 .069.05.126.12.127.07 0 .13-.054.13-.123l.01-1 1 .014c.07.001.13-.054.13-.123s-.05-.126-.12-.127l-1.13-.016zm1.89 1.897l-1.8-1.86-.18.175 1.81 1.86.17-.175z"
				></path>
				<circle
					cx="1076.58"
					cy="20.454"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-179.156 1076.58 20.454)"
				></circle>
				<path
					fill="#505F79"
					d="M1059.21 24.788c-.04.061-.01.137.05.17l.99.525c.06.033.14.01.17-.052a.127.127 0 00-.05-.169l-.88-.467.46-.884c.04-.061.01-.137-.05-.17a.126.126 0 00-.17.053l-.52.994zm2.55-.824l-2.48.763.07.24 2.48-.764-.07-.239z"
				></path>
				<circle
					cx="1061.79"
					cy="24.084"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(117.875 1061.79 24.084)"
				></circle>
				<path
					fill="#505F79"
					d="M1073.15 21.856c-.06-.035-.14-.016-.17.044l-.57.97a.12.12 0 00.04.17c.06.035.14.016.17-.044l.51-.861.86.507c.06.035.14.016.17-.044a.12.12 0 00-.04-.17l-.97-.572zm.71 2.586l-.65-2.51-.24.063.64 2.51.25-.063z"
				></path>
				<circle
					cx="1073.74"
					cy="24.473"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-149.484 1073.74 24.473)"
				></circle>
				<path
					fill="#505F79"
					d="M1087.39 22.874a.122.122 0 00-.13-.12l-1.12.05a.127.127 0 00-.12.13c0 .069.06.122.13.12l1-.044.04.999c.01.069.06.122.13.12a.127.127 0 00.12-.13l-.05-1.125zm-1.78 2l1.75-1.91-.18-.169-1.76 1.91.19.17z"
				></path>
				<circle
					cx="1085.52"
					cy="24.79"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-92.485 1085.52 24.79)"
				></circle>
				<path
					fill="#505F79"
					d="M1059.82 18.662c-.03-.056-.11-.07-.17-.03l-.92.642a.121.121 0 00-.03.174c.04.057.11.071.17.032l.82-.572.57.82c.04.057.12.07.18.031.05-.04.07-.117.03-.174l-.65-.923zm-.43 2.645l.46-2.551-.25-.044-.46 2.551.25.044z"
				></path>
				<circle
					cx="1059.27"
					cy="21.285"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-124.877 1059.27 21.285)"
				></circle>
				<path
					fill="#5F85EB"
					d="M1057.03 26.545h31.16V42.37c0 4.418-3.59 8-8 8h-15.16c-4.42 0-8-3.582-8-8V26.545z"
				></path>
				<path
					fill="#DFE1E6"
					d="M1055.2 12.8h34.82v1.498a4 4 0 01-4 4h-26.82a4 4 0 01-4-4V12.8z"
				></path>
				<circle cx="1071.69" cy="44.261" r="0.611" fill="#fff"></circle>
				<circle cx="1068.64" cy="43.039" r="0.611" fill="#fff"></circle>
				<circle cx="1071.69" cy="40.595" r="0.611" fill="#fff"></circle>
				<circle cx="1069.86" cy="38.762" r="0.611" fill="#fff"></circle>
				<circle cx="1072.3" cy="36.93" r="0.611" fill="#fff"></circle>
				<circle cx="1070.47" cy="33.876" r="0.611" fill="#fff"></circle>
				<circle cx="1073.52" cy="30.821" r="0.611" fill="#fff"></circle>
				<circle cx="1075.97" cy="35.097" r="0.611" fill="#fff"></circle>
				<circle cx="1074.14" cy="39.984" r="0.611" fill="#fff"></circle>
				<circle cx="1075.36" cy="44.261" r="0.611" fill="#fff"></circle>
				<circle cx="1077.19" cy="41.206" r="0.611" fill="#fff"></circle>
			</g>
			<g opacity="0.4">
				<rect
					width="119"
					height="59"
					x="928.898"
					y="134.5"
					fill="#fff"
					stroke="#DFE1E6"
					rx="3.5"
				></rect>
				<path
					stroke="#3C67F0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M1031.32 158.698l-10.07 10.08-4.59-4.582"
				></path>
				<path
					fill="#DFE1E6"
					d="M943.116 148.465h32.987v26.821a8 8 0 01-8 8h-16.987a8 8 0 01-8-8v-26.821z"
				></path>
				<path
					fill="#505F79"
					d="M952.278 154.088a.125.125 0 00.125-.125v-1.125a.125.125 0 00-.25 0v1h-1a.125.125 0 000 .25h1.125zm-1.921-1.869l1.833 1.833.177-.177-1.833-1.833-.177.177z"
				></path>
				<circle cx="950.446" cy="152.131" r="0.916" fill="#5F85EB"></circle>
				<path
					fill="#505F79"
					d="M953.459 156.174c.01.068.073.116.141.106l1.114-.159a.125.125 0 00-.035-.247l-.99.141-.141-.99a.125.125 0 00-.142-.106.124.124 0 00-.106.141l.159 1.114zm1.579-2.166l-1.555 2.073.2.15 1.555-2.073-.2-.15z"
				></path>
				<circle
					cx="955.139"
					cy="154.083"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(81.888 955.139 154.083)"
				></circle>
				<path
					fill="#505F79"
					d="M968.293 155.329a.126.126 0 00-.087-.154l-1.085-.3a.125.125 0 00-.153.088.123.123 0 00.087.153l.964.267-.267.964a.126.126 0 00.087.154.125.125 0 00.154-.088l.3-1.084zm-2.314 1.353l2.255-1.277-.123-.218-2.255 1.278.123.217z"
				></path>
				<circle
					cx="965.918"
					cy="156.574"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-74.541 965.918 156.574)"
				></circle>
				<path
					fill="#505F79"
					d="M971.36 151.064a.126.126 0 00-.087-.154l-1.085-.3a.125.125 0 10-.066.241l.963.267-.266.964a.125.125 0 10.241.066l.3-1.084zm-2.314 1.353l2.255-1.277-.123-.218-2.255 1.278.123.217z"
				></path>
				<circle
					cx="968.984"
					cy="152.309"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-74.541 968.984 152.309)"
				></circle>
				<path
					fill="#505F79"
					d="M961.782 150.47a.125.125 0 00-.127.123l-.017 1.125a.125.125 0 00.25.003l.015-1 1 .015a.125.125 0 00.004-.25l-1.125-.016zm1.893 1.897l-1.805-1.86-.18.175 1.806 1.859.179-.174z"
				></path>
				<circle
					cx="963.584"
					cy="152.454"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-179.156 963.584 152.454)"
				></circle>
				<path
					fill="#505F79"
					d="M946.207 156.788a.125.125 0 00.052.169l.995.526a.125.125 0 00.117-.221l-.884-.467.467-.884a.125.125 0 00-.221-.117l-.526.994zm2.551-.824l-2.477.763.074.239 2.476-.763-.073-.239z"
				></path>
				<circle
					cx="948.795"
					cy="156.084"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(117.875 948.795 156.084)"
				></circle>
				<path
					fill="#505F79"
					d="M960.152 153.856a.125.125 0 00-.172.044l-.571.969a.125.125 0 00.216.127l.507-.862.862.508a.125.125 0 00.171-.044.125.125 0 00-.044-.171l-.969-.571zm.705 2.585l-.648-2.509-.242.063.648 2.509.242-.063z"
				></path>
				<circle
					cx="960.738"
					cy="156.473"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-149.484 960.738 156.473)"
				></circle>
				<path
					fill="#505F79"
					d="M974.394 154.874a.125.125 0 00-.13-.12l-1.124.049a.125.125 0 10.011.25l.999-.043.043.999a.125.125 0 00.25-.011l-.049-1.124zm-1.784 2l1.751-1.91-.184-.169-1.752 1.91.185.169z"
				></path>
				<circle
					cx="972.517"
					cy="156.79"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-92.485 972.517 156.79)"
				></circle>
				<path
					fill="#505F79"
					d="M946.825 150.662a.125.125 0 00-.174-.031l-.923.643a.126.126 0 00-.031.174c.04.057.118.071.174.031l.821-.571.571.82a.125.125 0 00.206-.143l-.644-.923zm-.435 2.645l.456-2.551-.246-.044-.456 2.551.246.044z"
				></path>
				<circle
					cx="946.267"
					cy="153.285"
					r="0.916"
					fill="#5F85EB"
					transform="rotate(-124.877 946.267 153.285)"
				></circle>
				<path
					fill="#5F85EB"
					d="M944.032 158.545h31.155v15.824a8 8 0 01-8 8h-15.155a8 8 0 01-8-8v-15.824z"
				></path>
				<path
					fill="#DFE1E6"
					d="M942.199 144.8h34.82v1.498a4 4 0 01-4 4h-26.82a4 4 0 01-4-4V144.8z"
				></path>
				<circle cx="958.694" cy="176.26" r="0.611" fill="#fff"></circle>
				<circle cx="955.639" cy="175.039" r="0.611" fill="#fff"></circle>
				<circle cx="958.694" cy="172.595" r="0.611" fill="#fff"></circle>
				<circle cx="956.861" cy="170.762" r="0.611" fill="#fff"></circle>
				<circle cx="959.305" cy="168.93" r="0.611" fill="#fff"></circle>
				<circle cx="957.472" cy="165.875" r="0.611" fill="#fff"></circle>
				<circle cx="960.526" cy="162.821" r="0.611" fill="#fff"></circle>
				<circle cx="962.969" cy="167.097" r="0.611" fill="#fff"></circle>
				<circle cx="961.137" cy="171.984" r="0.611" fill="#fff"></circle>
				<circle cx="962.358" cy="176.26" r="0.611" fill="#fff"></circle>
				<circle cx="964.191" cy="173.206" r="0.611" fill="#fff"></circle>
			</g>
			<g opacity="0.4">
				<rect
					width="119"
					height="59"
					x="790.1"
					y="2.5"
					fill="#fff"
					stroke="#DFE1E6"
					rx="3.5"
				></rect>
				<path
					stroke="#3C67F0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M892.526 26.698l-10.08 10.08-4.581-4.582"
				></path>
				<path
					fill="#C1C7D0"
					d="M863.4 51.8h-63.6V33.146l1.205-5.83c.402 1.036 1.568 3.42 3.014 4.664 1.447 1.243 1.407-3.369 1.206-5.83.603 1.166 2.11 3.498 3.316 3.498 1.507 0 .603-6.218.904-5.441.301.777 2.713 5.44 4.22 6.218 1.507.777 1.808-5.44 2.11-3.11.301 2.333.603 2.333 3.014 0 2.412-2.33 1.507.778 1.809.778.301 0 0 3.887 1.205 3.887 1.206 0 2.11-4.664 2.11-3.887 0 .777 1.809 3.887 2.713 3.887.904 0 2.713-5.83 3.316-4.664.482.933 1.607 3.498 2.11 4.664.301-1.296.964-3.576 1.205-2.332.302 1.554 2.412 1.554 3.919-.778 1.206-1.865 1.708-8.29 1.808-11.27 1.005 2.461 3.256 7.617 4.22 8.55 1.206 1.166 2.11.777 2.713-1.166.603-1.943 2.411-3.498 3.014-.777.603 2.72 2.11 7.384 5.124 3.886.726-.842 1.085-1.459 1.249-1.845.272-.638.013-.646 0 0-.011.587.182 1.714 1.163 3.4 2.713 4.663.603 4.663 3.315 3.497 2.171-.932 3.316-2.72 3.618-3.497V51.8z"
				></path>
				<path
					fill="#505F79"
					d="M823.534 27.835a.376.376 0 000 .53l2.387 2.387a.375.375 0 10.53-.53L824.33 28.1l2.121-2.121a.374.374 0 10-.53-.53l-2.387 2.386zm6.531.53a.376.376 0 000-.53l-2.387-2.387a.375.375 0 10-.53.53l2.121 2.122-2.121 2.121a.374.374 0 10.53.53l2.387-2.386zm-6.265.11h6v-.75h-6v.75zM839.065 17.865a.376.376 0 000-.53l-2.387-2.387a.375.375 0 10-.53.53l2.121 2.122-2.121 2.121a.374.374 0 10.53.53l2.387-2.386zm-29.631-.53a.376.376 0 000 .53l2.387 2.387a.375.375 0 10.53-.53L810.23 17.6l2.121-2.121a.374.374 0 10-.53-.53l-2.387 2.386zm29.366-.11h-29.1v.75h29.1v-.75z"
				></path>
				<path
					stroke="#505F79"
					strokeDasharray="2 2"
					strokeWidth="0.5"
					d="M809.7 17.6v7.2"
				></path>
			</g>
			<g opacity="0.4">
				<rect
					width="119"
					height="59"
					x="737.301"
					y="68.5"
					fill="#fff"
					stroke="#DFE1E6"
					rx="3.5"
				></rect>
				<path
					stroke="#3C67F0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M839.726 92.698l-10.079 10.08-4.582-4.582"
				></path>
				<path
					fill="#C1C7D0"
					d="M810.601 117.8h-63.6V99.146l1.205-5.83c.402 1.036 1.568 3.42 3.015 4.663 1.446 1.244 1.406-3.368 1.205-5.829.603 1.166 2.11 3.498 3.316 3.498 1.507 0 .603-6.218.904-5.441.302.777 2.713 5.44 4.22 6.218 1.507.777 1.809-5.44 2.11-3.11.302 2.333.603 2.333 3.014 0 2.412-2.33 1.507.778 1.809.778.301 0 0 3.886 1.206 3.886 1.205 0 2.109-4.663 2.109-3.886 0 .778 1.809 3.886 2.713 3.886.905 0 2.713-5.829 3.316-4.663.482.933 1.607 3.498 2.11 4.663.301-1.295.964-3.575 1.206-2.331.301 1.554 2.411 1.554 3.918-.778 1.206-1.865 1.708-8.29 1.809-11.27 1.004 2.461 3.255 7.617 4.219 8.55 1.206 1.166 2.11.777 2.713-1.166.603-1.943 2.412-3.498 3.014-.777.603 2.72 2.11 7.384 5.125 3.886.726-.842 1.084-1.459 1.249-1.845.271-.638.012-.646 0 0-.011.587.181 1.714 1.162 3.4 2.713 4.663.603 4.663 3.316 3.498 2.17-.933 3.315-2.721 3.617-3.498V117.8z"
				></path>
				<path
					fill="#505F79"
					d="M770.736 93.835a.374.374 0 000 .53l2.386 2.387a.375.375 0 10.53-.53l-2.121-2.122 2.121-2.121a.374.374 0 10-.53-.53l-2.386 2.386zm6.53.53a.376.376 0 000-.53l-2.387-2.387a.375.375 0 10-.53.53l2.121 2.122-2.121 2.121a.375.375 0 10.53.53l2.387-2.386zm-6.265.11h6v-.75h-6v.75zM786.266 83.865a.376.376 0 000-.53l-2.387-2.387a.375.375 0 10-.53.53l2.121 2.122-2.121 2.121a.375.375 0 10.53.53l2.387-2.386zm-29.63-.53a.374.374 0 000 .53l2.386 2.387a.375.375 0 10.53-.53l-2.121-2.122 2.121-2.121a.374.374 0 10-.53-.53l-2.386 2.386zm29.365-.11h-29.1v.75h29.1v-.75z"
				></path>
				<path
					stroke="#505F79"
					strokeDasharray="2 2"
					strokeWidth="0.5"
					d="M756.901 83.6v7.2"
				></path>
			</g>
			<g opacity="0.4">
				<rect
					width="119"
					height="59"
					x="677.5"
					y="134.5"
					fill="#fff"
					stroke="#DFE1E6"
					rx="3.5"
				></rect>
				<path
					stroke="#3C67F0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M779.925 158.698l-10.079 10.08-4.582-4.582"
				></path>
				<path
					fill="#C1C7D0"
					d="M750.8 183.8h-63.6v-18.655l1.206-5.829c.402 1.036 1.567 3.42 3.014 4.664 1.447 1.243 1.406-3.369 1.206-5.83.602 1.166 2.109 3.498 3.315 3.498 1.507 0 .603-6.218.904-5.441.302.777 2.713 5.441 4.22 6.218 1.507.777 1.809-5.441 2.11-3.109.302 2.332.603 2.332 3.015 0 2.411-2.332 1.507.777 1.808.777.301 0 0 3.887 1.206 3.887 1.205 0 2.11-4.664 2.11-3.887 0 .778 1.808 3.887 2.712 3.887.905 0 2.713-5.83 3.316-4.664.482.933 1.608 3.498 2.11 4.664.302-1.296.965-3.576 1.206-2.332.301 1.554 2.411 1.554 3.918-.778 1.206-1.865 1.708-8.29 1.809-11.27 1.005 2.461 3.255 7.617 4.22 8.55 1.205 1.166 2.11.777 2.712-1.166.603-1.943 2.412-3.498 3.015-.777.603 2.72 2.11 7.384 5.124 3.886.726-.842 1.085-1.459 1.249-1.845.271-.638.012-.646 0 0-.011.587.182 1.714 1.162 3.4 2.713 4.663.603 4.663 3.316 3.497 2.17-.932 3.316-2.72 3.617-3.497V183.8z"
				></path>
				<path
					fill="#505F79"
					d="M710.935 159.835a.374.374 0 000 .53l2.386 2.387a.377.377 0 00.531 0 .377.377 0 000-.531l-2.122-2.121 2.122-2.121a.377.377 0 000-.531.377.377 0 00-.531 0l-2.386 2.387zm6.53.53a.374.374 0 000-.53l-2.386-2.387a.377.377 0 00-.531 0 .377.377 0 000 .531l2.122 2.121-2.122 2.121a.377.377 0 000 .531.377.377 0 00.531 0l2.386-2.387zm-6.265.11h6v-.75h-6v.75zM726.465 149.865a.374.374 0 000-.53l-2.386-2.387a.377.377 0 00-.531 0 .377.377 0 000 .531l2.122 2.121-2.122 2.121a.377.377 0 000 .531.377.377 0 00.531 0l2.386-2.387zm-29.63-.53a.374.374 0 000 .53l2.386 2.387a.377.377 0 00.531 0 .377.377 0 000-.531l-2.122-2.121 2.122-2.121a.377.377 0 000-.531.377.377 0 00-.531 0l-2.386 2.387zm29.365-.11h-29.1v.75h29.1v-.75z"
				></path>
				<path
					stroke="#505F79"
					strokeDasharray="2 2"
					strokeWidth="0.5"
					d="M697.1 149.6v7.2"
				></path>
			</g>
			<g opacity="0.4">
				<rect
					width="119"
					height="59"
					x="863.301"
					y="68.5"
					fill="#fff"
					stroke="#DFE1E6"
					rx="3.5"
				></rect>
				<path fill="#C1C7D0" d="M895.2 84.8H906.277V111.108H895.2z"></path>
				<path fill="#DFE1E6" d="M896.585 84.8H905.585V111.108H896.585z"></path>
				<path
					fill="#1D1D1D"
					d="M900.738 111.107l-2.165 3.75h4.33l-2.165-3.75zm.375 9v-5.625h-.75v5.625h.75zM900.738 84.8l2.165-3.75h-4.33l2.165 3.75zm-.375-9v5.625h.75V75.8h-.75z"
				></path>
				<path
					stroke="#3C67F0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M965.726 92.698l-10.079 10.08-4.582-4.582"
				></path>
				<path
					fill="#C1C7D0"
					d="M876.001 84.85H887.14V111.30499999999999H876.001z"
				></path>
				<path
					fill="#DFE1E6"
					d="M877.393 84.85H886.443V111.30499999999999H877.393z"
				></path>
				<path
					fill="#1D1D1D"
					d="M881.569 120.355l2.166-3.75h-4.331l2.165 3.75zm-.375-9.05v5.675h.75v-5.675h-.75zM881.569 75.8l-2.165 3.75h4.331l-2.166-3.75zm.375 9.05v-5.675h-.75v5.675h.75z"
				></path>
			</g>
			<g opacity="0.4">
				<rect
					width="119"
					height="59"
					x="916.5"
					y="2.5"
					fill="#fff"
					stroke="#DFE1E6"
					rx="3.5"
				></rect>
				<path
					fill="#C1C7D0"
					d="M948.399 18.8H959.476V45.108000000000004H948.399z"
				></path>
				<path
					fill="#DFE1E6"
					d="M949.784 18.8H958.784V45.108000000000004H949.784z"
				></path>
				<path
					fill="#1D1D1D"
					d="M953.937 45.107l-2.165 3.75h4.33l-2.165-3.75zm.375 9v-5.625h-.75v5.625h.75zM953.937 18.8l2.165-3.75h-4.33l2.165 3.75zm-.375-9v5.625h.75V9.8h-.75z"
				></path>
				<path
					stroke="#3C67F0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M1018.93 26.698l-10.08 10.08-4.59-4.582"
				></path>
				<path fill="#C1C7D0" d="M929.2 18.85H940.339V45.305H929.2z"></path>
				<path
					fill="#DFE1E6"
					d="M930.592 18.85H939.6419999999999V45.305H930.592z"
				></path>
				<path
					fill="#1D1D1D"
					d="M934.769 54.355l2.165-3.75h-4.33l2.165 3.75zm-.375-9.05v5.675h.75v-5.675h-.75zM934.769 9.8l-2.165 3.75h4.33l-2.165-3.75zm.375 9.05v-5.675h-.75v5.675h.75z"
				></path>
			</g>
			<g opacity="0.4">
				<rect
					width="119"
					height="59"
					x="803.5"
					y="134.5"
					fill="#fff"
					stroke="#DFE1E6"
					rx="3.5"
				></rect>
				<path fill="#C1C7D0" d="M835.399 150.8H846.476V177.108H835.399z"></path>
				<path fill="#DFE1E6" d="M836.784 150.8H845.784V177.108H836.784z"></path>
				<path
					fill="#1D1D1D"
					d="M840.937 177.107l-2.165 3.75h4.33l-2.165-3.75zm.375 9v-5.625h-.75v5.625h.75zM840.937 150.8l2.165-3.75h-4.33l2.165 3.75zm-.375-9v5.625h.75V141.8h-.75z"
				></path>
				<path
					stroke="#3C67F0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M905.925 158.698l-10.079 10.08-4.582-4.582"
				></path>
				<path fill="#C1C7D0" d="M816.2 150.85H827.339V177.305H816.2z"></path>
				<path
					fill="#DFE1E6"
					d="M817.592 150.85H826.6419999999999V177.305H817.592z"
				></path>
				<path
					fill="#1D1D1D"
					d="M821.769 186.355l2.165-3.75h-4.33l2.165 3.75zm-.375-9.05v5.675h.75v-5.675h-.75zM821.769 141.8l-2.165 3.75h4.33l-2.165-3.75zm.375 9.05v-5.675h-.75v5.675h.75z"
				></path>
			</g>
			<g opacity="0.4">
				<rect
					width="119"
					height="59"
					x="1055.5"
					y="134.5"
					fill="#fff"
					stroke="#DFE1E6"
					rx="3.5"
				></rect>
				<path fill="#C1C7D0" d="M1087.4 150.8H1098.477V177.108H1087.4z"></path>
				<path fill="#DFE1E6" d="M1088.78 150.8H1097.78V177.108H1088.78z"></path>
				<path
					fill="#1D1D1D"
					d="M1092.94 177.107l-2.17 3.75h4.33l-2.16-3.75zm.37 9v-5.625h-.75v5.625h.75zM1092.94 150.8l2.16-3.75h-4.33l2.17 3.75zm-.38-9v5.625h.75V141.8h-.75z"
				></path>
				<path
					stroke="#3C67F0"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					d="M1157.93 158.698l-10.08 10.08-4.59-4.582"
				></path>
				<path fill="#C1C7D0" d="M1068.2 150.85H1079.339V177.305H1068.2z"></path>
				<path
					fill="#DFE1E6"
					d="M1069.59 150.85H1078.6399999999999V177.305H1069.59z"
				></path>
				<path
					fill="#1D1D1D"
					d="M1073.77 186.355l2.16-3.75h-4.33l2.17 3.75zm-.38-9.05v5.675h.75v-5.675h-.75zM1073.77 141.8l-2.17 3.75h4.33l-2.16-3.75zm.37 9.05v-5.675h-.75v5.675h.75z"
				></path>
			</g>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M801.261 173.45c-1.062-1.246-1.78-2.773-1.66-4.484.346-4.931 7.209-3.846 8.544-1.358 1.335 2.489 1.176 8.801-.549 9.246-.688.177-2.154-.258-3.646-1.157l.936 6.625h-5.529l1.904-8.872z"
				clipRule="evenodd"
			></path>
			<path
				fill="#1D1D1D"
				fillRule="evenodd"
				d="M797.19 167.324c-.179-.177-.276-.429-.36-.669a6.66 6.66 0 01-.231-.76c-.116-.518-.117-1.114.253-1.517.296-.322.741-.467 1.151-.499a2.12 2.12 0 01.817.104c.26.085.485.256.737.362.009-.559.105-1.143.388-1.62a1.615 1.615 0 011.203-.784c.508-.07 1.009.049 1.464.295.114.061.227.126.335.199.09.061.184.135.29.16.118.027.177-.041.254-.127.09-.101.192-.19.299-.269.475-.35 1.098-.493 1.648-.284.254.097.501.26.673.486.153.201.262.52.474.66.09.06.153-.019.235-.077.123-.088.24-.184.364-.269.175-.12.364-.21.566-.256.297-.069.706-.053.791.335.032.146.007.302-.003.449-.014.202-.029.403-.046.605-.014.153-.03.3-.059.451-.016.08-.064.229-.029.309.042.1.24.043.325.047.167.007.335.036.489.107.12.056.246.137.296.274a.491.491 0 01.009.258c-.018.104.002.159.015.264.225-.11 1.002-.356 1.1.046.033.134-.041.285-.098.398a3.41 3.41 0 01-.362.552c-.278.36-.609.671-.966.937a.632.632 0 01.165 1.059c-.159.144-.374.176-.575.167-.074-.003-.18-.032-.238-.019-.032.007-.065.034-.108.037-.284.019-.605-.049-.883-.112a2.915 2.915 0 01-1.347-.705c-.098-.091-.179-.144-.311-.143-.116.002-.229.033-.345.036-.306.008-.582-.142-.867-.241a3.256 3.256 0 01-.283 1.152c-.128.282-.396.323-.659.348-.84.079-1.684-.01-2.526-.008.815.184 1.657.26 2.465.476.365.098.086.344-.07.515a2.98 2.98 0 00-.581.995c-.398-.519-1.103-.862-1.727-.702-.69.176-1.175 1.109-.734 1.779.256.389.718.54 1.103.707-.3.312-.373.773-.553 1.161-.09.191-.215.408-.414.474-.074.025-.16.027-.218.087-.072.075-.089.158-.181.222-.367.255-.941.235-1.266-.098-.294-.301-.218-.777.028-1.083-.391-.163-1.104-.373-.948-.982-.848-.056-2.906-1.431-1.768-2.435-.625-.353-1.198-1.215-.797-1.976.309-.585 1.027-.846 1.616-.878z"
				clipRule="evenodd"
			></path>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M789.364 211.121h17.798l2.332 28.567H783l6.364-28.567z"
				clipRule="evenodd"
			></path>
			<path
				fill="#DFE1E6"
				fillRule="evenodd"
				d="M789.364 211.121h5.474l3.276 28.567H783l6.364-28.567z"
				clipRule="evenodd"
			></path>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M827.453 206.226c1.898-1.14 5.082.324 5.08 1.954-.007 4.876-4.457 27.857-4.689 29.309-.232 1.453-2.721 1.837-2.866.005-.231-2.915-.659-13.004-.231-17.674.177-1.928.368-3.764.556-5.401-2.335 4.216-5.947 10.254-10.834 18.112l-2.653-1.213c2.083-6.864 3.792-11.71 5.125-14.538 2.283-4.842 4.424-8.989 5.325-10.449 1.417-2.298 3.884-1.496 5.187-.105z"
				clipRule="evenodd"
			></path>
			<path
				fill="#0B1730"
				fillRule="evenodd"
				d="M812.005 227.506l9.107-21.572c2.181-3.51 8.298.714 7.554 2.749-1.689 4.621-10.279 19.581-10.782 20.958l-5.879-2.135z"
				clipRule="evenodd"
			></path>
			<path
				fill="#1D1D1D"
				fillRule="evenodd"
				d="M811.089 229.425c-.491.138-.8.282-.927.434-.155.184-.318.498-.488.942l11.844 8.453c.723-.276.77-.67.14-1.183-.63-.512-1.151-.941-1.563-1.286l-4.502-5.123c-.087-.099-.23-.164-.321-.147l-.004.001-.705.166c-.731-.299-1.288-.585-1.672-.859-.312-.223-.691-.592-1.137-1.106-.172-.198-.459-.331-.642-.297l-.023.005zM824.395 236.325c-.258.688-.386 1.203-.386 1.545 0 .415.069 1.021.207 1.818h12.994c.338-1.116.143-1.719-.584-1.808a71.075 71.075 0 01-1.814-.241l-6.039-2.692a.23.23 0 00-.304.116l-.003.006-.388.942c-.674.314-1.221.472-1.642.472-.342 0-.815-.139-1.417-.415a.461.461 0 00-.611.227l-.013.03z"
				clipRule="evenodd"
			></path>
			<path
				fill="#253858"
				fillRule="evenodd"
				d="M833.171 209.838c0 6.12-2.907 20.397-2.909 21.745l-6.253.005c.915-12.739 1.239-19.178.973-19.315-.399-.206-16.369 1.595-21.158 1.595-6.907 0-9.764-4.356-9.996-12.424h13.682c2.785.298 17.316 3.229 22.712 4.236 2.311.432 2.949 2.472 2.949 4.158z"
				clipRule="evenodd"
			></path>
			<rect
				width="63.01"
				height="51.004"
				fill="#505F79"
				rx="4"
				transform="matrix(-.23866 -.19409 -.21228 .23783 842.209 180.498)"
			></rect>
			<rect
				width="57.462"
				height="47.696"
				fill="#fff"
				rx="4"
				transform="matrix(-.23868 -.19457 -.21223 .23848 841.193 180.352)"
			></rect>
			<rect
				width="47.939"
				height="0.989"
				fill="#C1C7D0"
				rx="0.495"
				transform="matrix(-.23675 -.14427 -.21823 .16586 840.379 176.872)"
			></rect>
			<rect
				width="47.939"
				height="0.989"
				fill="#C1C7D0"
				rx="0.495"
				transform="matrix(-.23675 -.14427 -.21823 .16586 832.959 186.57)"
			></rect>
			<rect
				width="47.939"
				height="0.989"
				fill="#C1C7D0"
				rx="0.495"
				transform="matrix(-.23675 -.14427 -.21823 .16586 839.818 177.604)"
			></rect>
			<rect
				width="47.939"
				height="0.989"
				fill="#C1C7D0"
				rx="0.495"
				transform="matrix(-.23675 -.14427 -.21823 .16586 832.398 187.301)"
			></rect>
			<rect
				width="47.939"
				height="0.989"
				fill="#C1C7D0"
				rx="0.495"
				transform="matrix(-.23675 -.14427 -.21823 .16586 839.258 178.336)"
			></rect>
			<rect
				width="47.939"
				height="0.989"
				fill="#C1C7D0"
				rx="0.495"
				transform="matrix(-.23675 -.14427 -.21823 .16586 831.838 188.034)"
			></rect>
			<rect
				width="47.939"
				height="0.989"
				fill="#C1C7D0"
				rx="0.495"
				transform="matrix(-.23675 -.14427 -.21823 .16586 838.697 179.067)"
			></rect>
			<rect
				width="47.939"
				height="0.989"
				fill="#C1C7D0"
				rx="0.495"
				transform="matrix(-.23675 -.14427 -.21823 .16586 831.279 188.765)"
			></rect>
			<rect
				width="47.939"
				height="0.989"
				fill="#C1C7D0"
				rx="0.495"
				transform="matrix(-.23675 -.14427 -.21823 .16586 838.138 179.799)"
			></rect>
			<rect
				width="47.939"
				height="0.989"
				fill="#C1C7D0"
				rx="0.495"
				transform="matrix(-.23675 -.14427 -.21823 .16586 830.719 189.497)"
			></rect>
			<rect
				width="47.939"
				height="0.989"
				fill="#C1C7D0"
				rx="0.495"
				transform="matrix(-.23675 -.14427 -.21823 .16586 837.578 180.531)"
			></rect>
			<rect
				width="47.939"
				height="0.989"
				fill="#C1C7D0"
				rx="0.495"
				transform="matrix(-.23675 -.14427 -.21823 .16586 830.158 190.229)"
			></rect>
			<rect
				width="47.939"
				height="0.989"
				fill="#C1C7D0"
				rx="0.495"
				transform="matrix(-.23675 -.14427 -.21823 .16586 837.017 181.264)"
			></rect>
			<rect
				width="47.939"
				height="0.989"
				fill="#C1C7D0"
				rx="0.495"
				transform="matrix(-.23675 -.14427 -.21823 .16586 829.599 190.961)"
			></rect>
			<rect
				width="25.343"
				height="0.995"
				fill="#C1C7D0"
				rx="0.497"
				transform="matrix(-.23688 -.1472 -.21788 .17028 830.972 178.992)"
			></rect>
			<rect
				width="25.343"
				height="0.995"
				fill="#C1C7D0"
				rx="0.497"
				transform="matrix(-.23688 -.1472 -.21788 .17029 828.732 181.92)"
			></rect>
			<rect
				width="25.343"
				height="0.995"
				fill="#C1C7D0"
				rx="0.497"
				transform="matrix(-.23688 -.1472 -.21788 .17029 830.412 179.724)"
			></rect>
			<rect
				width="25.343"
				height="0.995"
				fill="#C1C7D0"
				rx="0.497"
				transform="matrix(-.23688 -.1472 -.21788 .17029 828.172 182.652)"
			></rect>
			<rect
				width="25.343"
				height="0.995"
				fill="#C1C7D0"
				rx="0.497"
				transform="matrix(-.23688 -.1472 -.21788 .17028 829.851 180.457)"
			></rect>
			<rect
				width="25.343"
				height="0.995"
				fill="#C1C7D0"
				rx="0.497"
				transform="matrix(-.23688 -.1472 -.21788 .17028 829.292 181.188)"
			></rect>
			<rect
				width="22.883"
				height="12.075"
				fill="#C1C7D0"
				rx="4"
				transform="matrix(-.23835 -.18537 -.2133 .22597 836.318 183.273)"
			></rect>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M809.64 187.091l7.996-4.38c1.33-1.579 2.64-2.805 3.931-3.679.394-.165 1.084-.264.439.88-.645 1.145-1.272 2.378-.943 2.731.328.353 1.148-.14 1.54.446.261.39-1.139 1.008-4.2 1.851l-5.81 5.32-2.953-3.169zm12.524 3.695l3.513-1.93c.492-1.875 1.082-2.819 1.767-2.83.527-.245-.39 2.124.608 1.919.998-.206 3.434-2.018 3.854-1.65.621.545.067 2.216-.585 3.047-1.22 1.554-2.229 2.086-4.725 2.781-1.305.364-2.715 1.093-4.232 2.188l-.2-3.525z"
				clipRule="evenodd"
			></path>
			<path
				fill="#DFE1E6"
				fillRule="evenodd"
				d="M805.746 186.116c2.801-.03 6.536-.621 10.521-3.151l1.566 3.49c-2.749 2.728-6.615 4.97-9.994 4.875-2.664-.075-4.025-3.297-2.093-5.214z"
				clipRule="evenodd"
			></path>
			<path
				fill="#FAFBFC"
				fillRule="evenodd"
				d="M809.518 191.713c2.974.741 7.96-.133 14.959-2.624 1.737 3.592 2.539 6.153 2.408 7.682-6.004 3.137-11.151 4.535-15.39 4.627.134 2.516-.172 4.91-1.201 7.097-2.114 4.497-14.498.332-18.661 1.152-2.607-8.468 2.434-11.909 2.438-17.327.004-5.933 4.769-15.066 5.47-15.066h6.392c.353 4.531 2.128 9.525 3.585 14.459z"
				clipRule="evenodd"
			></path>
			<path
				fill="#fff"
				stroke="#DFE1E6"
				strokeWidth="2"
				d="M849.629 111.384h-45.837c-6.075 0-11 4.925-11 11v21.205c0 6.075 4.925 11 11 11h6.94c.797 0 1.634.001 2.579.43.616.28 1.064.715 1.34 1.261.266.525.344 1.095.339 1.623-.012 1.032-.354 2.151-.644 3.026l-.167.495-.055.162c.419-.255.885-.56 1.405-.907.199-.132.405-.271.617-.414.686-.46 1.441-.967 2.247-1.48 2.119-1.348 4.665-2.786 7.582-3.733 1.071-.348 2.174-.463 3.234-.463h20.42c6.075 0 11-4.925 11-11v-21.205c0-6.075-4.925-11-11-11zm-35.843 51.408v-.009.009zm-.754-.629l-.009.002.009-.002z"
			></path>
			<path
				stroke="#63F19C"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth="2"
				d="M849.558 126.55l-11.855 11.855-5.389-5.389"
			></path>
			<path
				fill="#DFE1E6"
				d="M803.593 123.37h21.331v14.516a8 8 0 01-8 8h-5.331a8 8 0 01-8-8V123.37z"
			></path>
			<path
				fill="#505F79"
				d="M809.517 127.05a.125.125 0 00.125-.125V125.8a.125.125 0 00-.25 0v1h-1a.125.125 0 000 .25h1.125zm-1.273-1.221l1.185 1.185.176-.177-1.185-1.185-.176.177z"
			></path>
			<circle cx="808.333" cy="125.74" r="0.593" fill="#5F85EB"></circle>
			<path
				fill="#505F79"
				d="M810.237 128.361a.124.124 0 00.141.106l1.114-.159a.125.125 0 00-.035-.247l-.99.141-.142-.99a.125.125 0 00-.247.035l.159 1.114zm1.029-1.433l-1.005 1.34.199.15 1.006-1.34-.2-.15z"
			></path>
			<circle
				cx="811.367"
				cy="127.003"
				r="0.593"
				fill="#5F85EB"
				transform="rotate(81.888 811.367 127.003)"
			></circle>
			<path
				fill="#505F79"
				d="M819.916 127.82a.125.125 0 00-.087-.153l-1.085-.3a.125.125 0 10-.066.241l.963.266-.266.964a.124.124 0 00.087.154.126.126 0 00.154-.087l.3-1.085zm-1.517.902l1.458-.826-.123-.218-1.458.827.123.217z"
			></path>
			<circle
				cx="818.338"
				cy="128.613"
				r="0.593"
				fill="#5F85EB"
				transform="rotate(-74.541 818.338 128.613)"
			></circle>
			<path
				fill="#505F79"
				d="M821.899 125.063a.124.124 0 00-.087-.154l-1.084-.3a.124.124 0 00-.154.087.126.126 0 00.087.154l.964.266-.266.964a.125.125 0 10.24.067l.3-1.084zm-1.517.901l1.458-.826-.123-.218-1.458.827.123.217z"
			></path>
			<circle
				cx="820.32"
				cy="125.855"
				r="0.593"
				fill="#5F85EB"
				transform="rotate(-74.541 820.32 125.855)"
			></circle>
			<path
				fill="#505F79"
				d="M815.665 124.622a.125.125 0 00-.127.123l-.017 1.125a.125.125 0 00.25.004l.015-1 1 .015a.125.125 0 10.003-.25l-1.124-.017zm1.255 1.24l-1.168-1.202-.179.174 1.168 1.203.179-.175z"
			></path>
			<circle
				cx="816.829"
				cy="125.949"
				r="0.593"
				fill="#5F85EB"
				transform="rotate(-179.156 816.829 125.949)"
			></circle>
			<path
				fill="#505F79"
				d="M805.552 128.731a.125.125 0 00.052.169l.995.526a.124.124 0 00.169-.052.126.126 0 00-.052-.169l-.884-.467.467-.884a.124.124 0 00-.052-.169.126.126 0 00-.169.052l-.526.994zm1.676-.554l-1.602.493.074.239 1.601-.493-.073-.239z"
			></path>
			<circle
				cx="807.265"
				cy="128.296"
				r="0.593"
				fill="#5F85EB"
				transform="rotate(117.875 807.265 128.296)"
			></circle>
			<path
				fill="#505F79"
				d="M814.631 126.818a.124.124 0 00-.171.044l-.571.969a.124.124 0 00.044.171.124.124 0 00.171-.044l.508-.861.861.507a.125.125 0 10.127-.215l-.969-.571zm.477 1.699l-.419-1.623-.242.063.419 1.622.242-.062z"
			></path>
			<circle
				cx="814.988"
				cy="128.548"
				r="0.593"
				fill="#5F85EB"
				transform="rotate(-149.484 814.988 128.548)"
			></circle>
			<path
				fill="#505F79"
				d="M823.862 127.512a.124.124 0 00-.13-.119l-1.124.049a.124.124 0 10.011.249l.999-.043.043.999a.125.125 0 10.25-.011l-.049-1.124zm-1.165 1.326l1.133-1.236-.185-.169-1.132 1.236.184.169z"
			></path>
			<circle
				cx="822.605"
				cy="128.753"
				r="0.593"
				fill="#5F85EB"
				transform="rotate(-92.485 822.605 128.753)"
			></circle>
			<path
				fill="#505F79"
				d="M806.027 124.765a.126.126 0 00-.174-.031l-.923.644a.124.124 0 00-.031.174c.039.056.117.07.174.031l.82-.572.572.82c.04.057.118.071.174.031a.124.124 0 00.031-.174l-.643-.923zm-.274 1.744l.294-1.65-.246-.044-.294 1.65.246.044z"
			></path>
			<circle
				cx="805.631"
				cy="126.487"
				r="0.593"
				fill="#5F85EB"
				transform="rotate(-124.877 805.631 126.487)"
			></circle>
			<path
				fill="#5F85EB"
				d="M804.185 129.888h20.146v7.406a8 8 0 01-8 8h-4.146a8 8 0 01-8-8v-7.406z"
			></path>
			<path
				fill="#DFE1E6"
				d="M803 121h22.516a3.555 3.555 0 01-3.555 3.555h-15.406A3.555 3.555 0 01803 121z"
			></path>
			<circle cx="813.665" cy="141.343" r="0.395" fill="#fff"></circle>
			<circle cx="811.69" cy="140.553" r="0.395" fill="#fff"></circle>
			<circle cx="813.665" cy="138.973" r="0.395" fill="#fff"></circle>
			<circle cx="812.481" cy="137.788" r="0.395" fill="#fff"></circle>
			<circle cx="814.061" cy="136.603" r="0.395" fill="#fff"></circle>
			<circle cx="812.876" cy="134.628" r="0.395" fill="#fff"></circle>
			<circle cx="814.85" cy="132.653" r="0.395" fill="#fff"></circle>
			<circle cx="816.43" cy="135.418" r="0.395" fill="#fff"></circle>
			<circle cx="815.245" cy="138.578" r="0.395" fill="#fff"></circle>
			<circle cx="816.036" cy="141.343" r="0.395" fill="#fff"></circle>
			<circle cx="817.22" cy="139.368" r="0.395" fill="#fff"></circle>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M1024.76 137.97c1.24-1.453 2.08-3.234 1.94-5.23-.41-5.75-8.41-4.485-9.97-1.583-1.56 2.903-1.37 10.264.64 10.783.8.207 2.51-.3 4.25-1.349l-1.09 7.726h6.45l-2.22-10.347z"
				clipRule="evenodd"
			></path>
			<path
				fill="#1D1D1D"
				fillRule="evenodd"
				d="M1021.3 133.375c-.25 3.439-.9 5.158-1.96 5.158-1.59 0-2.93-1.009-3.63-1.009-.51 0-.4.636-.06 1.108.28.389 1.63.764 1.63 1.367s-1.07.136-1.07.494c0 .591-.19 1.704.84 1.704 1.04 0 4.02-.379 4.53-1.921.33-.996.54-3.058.64-6.188a1.61 1.61 0 011.8 1.601c0 .452-.18.86-.48 1.153.37.527.79 1.077 1.27 1.644 1.8-1.08 3.13-4.24 1.37-7.277-.48-1.487-1.58-2.282-2.88-2.651-1.06-.44-2.15-.49-3.07-.226-1.81.155-3.43.707-3.89 1.035 0 1.713.34 2.221 3.17 2.176.7.368 1.23 1.002 1.79 1.832z"
				clipRule="evenodd"
			></path>
			<path
				fill="#0B1730"
				fillRule="evenodd"
				d="M1029.13 170.618l-9.74 34.184-7.11 25.196h-5.18l7.55-59.38h14.48z"
				clipRule="evenodd"
			></path>
			<path
				fill="#253858"
				fillRule="evenodd"
				d="M1031.73 170.618c.23 17.619 1 27.229 1.23 28.83.24 1.601 3.82 11.784 10.73 30.55h-5.37c-8.8-18.003-13.72-28.186-14.74-30.55-1.03-2.364-4.19-11.974-8.96-28.83h17.11z"
				clipRule="evenodd"
			></path>
			<path
				fill="#1D1D1D"
				fillRule="evenodd"
				d="M1044.18 234.834l-.27-5.642h-5.65c-2.67 1.881-6.16 3.403-10.47 4.568v1.074H1038l3.49-.537v.537h2.69zM1013.01 234.834l-.27-5.642h-5.64c-2.68 1.881-6.17 3.403-10.483 4.568v1.074h10.213l3.49-.537v.537h2.69z"
				clipRule="evenodd"
			></path>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M1006.46 166.215l-8.108 6.876c-2.275.788-4.158 1.702-5.649 2.742-.344.361-.74 1.07.776.846 1.516-.224 3.12-.391 3.367.114.246.505-.632 1.192-.158 1.863.316.448 1.573-.818 3.772-3.798l8.22-4.103-2.22-4.54zm36.73-2.976l-4.76.692c5.11 11.083 7.85 16.944 8.22 17.584.83 1.439-.15 3.946-.53 5.273 1.93.557 1.31-2.423 3.75-1.68 2.23.679 4.06 2.217 6.27.388.27-.225.5-.96-.36-1.388-2.15-1.068-5.3-3.021-5.79-3.74-.67-.982-2.94-6.691-6.8-17.129z"
				clipRule="evenodd"
			></path>
			<path
				fill="#EBECF0"
				fillRule="evenodd"
				d="M1023.3 143.839l-2.95-.975c-4 9.992-9.97 19.661-19.93 27.827l2.46 4.252c8.76-5.16 17.05-9.168 20.42-16.911 1.13-2.599.09-11.086 0-14.193z"
				clipRule="evenodd"
			></path>
			<path
				fill="#3C67F0"
				fillRule="evenodd"
				d="M1033.43 171.962c-8.45.896-15.1.896-19.95 0-.6-.111-.69-1.117-.41-1.8 4.33-10.634 7.31-19.912 7.31-27.298.8.401 2.19.461 4.18.18 5.35 7.612 7.81 16.345 8.87 28.918z"
				clipRule="evenodd"
			></path>
			<path
				fill="#EBECF0"
				fillRule="evenodd"
				d="M1025.89 142.406h-2.23c-.52 3.054-2.15 44.404-7.21 57.931h27.35c-.59-12.391-5.27-27.277-10.01-39.733 4.19 3.835 7.85 10.332 10.96 19.492l5.12-1.315c-3.81-16.416-11.64-28.482-23.49-36.198-.03-.059-.05-.118-.08-.177h-.2c-.07-.046-.14-.093-.22-.139l.01.139z"
				clipRule="evenodd"
			></path>
			<path
				fill="#fff"
				stroke="#DFE1E6"
				strokeWidth="2"
				d="M973 78h46.96c6.07 0 11 4.925 11 11v21.952c0 6.075-4.93 11-11 11h-7.25c-.81 0-1.65.001-2.61.436-.62.283-1.08.723-1.35 1.274-.27.531-.35 1.107-.35 1.643.02 1.045.36 2.181.66 3.07.06.179.11.346.17.503.02.071.04.14.07.208-.44-.265-.93-.584-1.47-.948-.2-.134-.41-.274-.63-.419-.7-.47-1.46-.986-2.29-1.509-2.15-1.373-4.74-2.835-7.712-3.796-1.071-.347-2.174-.462-3.234-.462H973c-6.075 0-11-4.925-11-11V89c0-6.075 4.925-11 11-11zm36.63 52.272v-.007V130.272zm.74-.621l.01.002-.01-.002z"
			></path>
			<path
				fill="#DFE1E6"
				d="M969.406 89.735h22.507v15.757a8 8 0 01-8 8h-6.507a8 8 0 01-8-8V89.735z"
			></path>
			<path
				fill="#505F79"
				d="M975.657 93.611a.125.125 0 00.125-.125v-1.125a.125.125 0 10-.25 0v1h-1a.125.125 0 100 .25h1.125zm-1.339-1.287l1.25 1.25.177-.176-1.25-1.25-.177.176z"
			></path>
			<circle cx="974.407" cy="92.236" r="0.625" fill="#5F85EB"></circle>
			<path
				fill="#505F79"
				d="M976.424 95a.124.124 0 00.141.106l1.114-.159a.125.125 0 00-.035-.247l-.99.141-.142-.99a.125.125 0 10-.247.035l.159 1.114zm1.085-1.507l-1.062 1.414.2.15 1.062-1.414-.2-.15z"
			></path>
			<circle
				cx="977.609"
				cy="93.568"
				r="0.625"
				fill="#5F85EB"
				transform="rotate(81.888 977.609 93.568)"
			></circle>
			<path
				fill="#505F79"
				d="M986.622 94.429a.124.124 0 00-.087-.154l-1.084-.3a.125.125 0 00-.067.241l.964.267-.266.963a.124.124 0 00.087.154.126.126 0 00.154-.087l.299-1.084zm-1.597.947l1.539-.872-.124-.217-1.538.871.123.218z"
			></path>
			<circle
				cx="984.964"
				cy="95.267"
				r="0.625"
				fill="#5F85EB"
				transform="rotate(-74.541 984.964 95.267)"
			></circle>
			<path
				fill="#505F79"
				d="M988.715 91.519a.124.124 0 00-.087-.154l-1.084-.3a.125.125 0 10-.067.241l.964.267-.267.964a.125.125 0 00.088.153.124.124 0 00.153-.087l.3-1.084zm-1.597.947l1.538-.872-.123-.217-1.538.872.123.217z"
			></path>
			<circle
				cx="987.055"
				cy="92.357"
				r="0.625"
				fill="#5F85EB"
				transform="rotate(-74.541 987.055 92.357)"
			></circle>
			<path
				fill="#505F79"
				d="M982.142 91.063a.125.125 0 00-.127.123l-.016 1.125a.125.125 0 10.25.003l.015-1 .999.015a.125.125 0 00.004-.25l-1.125-.016zm1.32 1.306l-1.232-1.268-.179.174 1.232 1.269.179-.175z"
			></path>
			<circle
				cx="983.372"
				cy="92.457"
				r="0.625"
				fill="#5F85EB"
				transform="rotate(-179.156 983.372 92.457)"
			></circle>
			<path
				fill="#505F79"
				d="M971.481 95.395a.124.124 0 00.052.169l.994.526a.125.125 0 00.117-.221l-.884-.468.468-.883a.125.125 0 00-.221-.117l-.526.994zm1.763-.582l-1.69.521.074.239 1.69-.52-.074-.24z"
			></path>
			<circle
				cx="973.281"
				cy="94.933"
				r="0.625"
				fill="#5F85EB"
				transform="rotate(117.875 973.281 94.933)"
			></circle>
			<path
				fill="#505F79"
				d="M981.05 93.379a.126.126 0 00-.172.044l-.571.969a.125.125 0 00.216.127l.507-.862.862.508a.125.125 0 00.127-.215l-.969-.572zm.499 1.788l-.442-1.712-.242.063.442 1.712.242-.063z"
			></path>
			<circle
				cx="981.429"
				cy="95.198"
				r="0.625"
				fill="#5F85EB"
				transform="rotate(-149.484 981.429 95.198)"
			></circle>
			<path
				fill="#505F79"
				d="M990.787 94.106a.125.125 0 00-.13-.12l-1.124.05a.125.125 0 00-.12.13.126.126 0 00.131.119l.999-.043.043.999a.125.125 0 00.13.12.125.125 0 00.12-.131l-.049-1.124zm-1.228 1.393l1.195-1.303-.184-.17-1.195 1.304.184.17z"
			></path>
			<circle
				cx="989.466"
				cy="95.415"
				r="0.625"
				fill="#5F85EB"
				transform="rotate(-92.485 989.466 95.415)"
			></circle>
			<path
				fill="#505F79"
				d="M971.969 91.211a.126.126 0 00-.175-.03l-.922.642a.125.125 0 00-.032.174c.04.057.118.071.175.031l.82-.571.572.82a.125.125 0 10.205-.143l-.643-.923zm-.291 1.834l.311-1.74-.246-.044-.311 1.74.246.044z"
			></path>
			<circle
				cx="971.556"
				cy="93.023"
				r="0.625"
				fill="#5F85EB"
				transform="rotate(-124.877 971.556 93.023)"
			></circle>
			<path
				fill="#5F85EB"
				d="M970.031 96.612h21.257v8.255a8 8 0 01-8 8h-5.257a8 8 0 01-8-8v-8.255z"
			></path>
			<path
				fill="#DFE1E6"
				d="M968.781 87.234h23.757a3.75 3.75 0 01-3.751 3.751h-16.255a3.75 3.75 0 01-3.751-3.75z"
			></path>
			<circle cx="980.036" cy="108.699" r="0.417" fill="#fff"></circle>
			<circle cx="977.952" cy="107.865" r="0.417" fill="#fff"></circle>
			<circle cx="980.036" cy="106.198" r="0.417" fill="#fff"></circle>
			<circle cx="978.786" cy="104.948" r="0.417" fill="#fff"></circle>
			<circle cx="980.453" cy="103.697" r="0.417" fill="#fff"></circle>
			<circle cx="979.202" cy="101.614" r="0.417" fill="#fff"></circle>
			<circle cx="981.286" cy="99.53" r="0.417" fill="#fff"></circle>
			<circle cx="982.953" cy="102.447" r="0.417" fill="#fff"></circle>
			<circle cx="981.703" cy="105.781" r="0.417" fill="#fff"></circle>
			<circle cx="982.536" cy="108.699" r="0.417" fill="#fff"></circle>
			<circle cx="983.787" cy="106.615" r="0.417" fill="#fff"></circle>
			<path
				fill="#253858"
				d="M1013.65 92.115c1.28 0 2.41.21 3.39.63 1 .4 1.78.98 2.34 1.74.56.76.84 1.67.84 2.73 0 1.16-.26 2.14-.78 2.94a4.95 4.95 0 01-2.04 1.8c-.84.42-1.78.69-2.82.81v3.21h-2.49v-4.5c1.34-.2 2.42-.46 3.24-.78.82-.34 1.42-.76 1.8-1.26.38-.52.57-1.16.57-1.92 0-.94-.35-1.7-1.05-2.28-.7-.58-1.76-.87-3.18-.87-1.32 0-2.4.37-3.24 1.11-.82.72-1.31 1.71-1.47 2.97l-2.13-1.23c.22-1.02.64-1.91 1.26-2.67a6.34 6.34 0 012.4-1.8c.98-.42 2.1-.63 3.36-.63zm-.36 16.5c.58 0 1.05.18 1.41.54.36.36.54.83.54 1.41s-.18 1.05-.54 1.41c-.36.36-.83.54-1.41.54s-1.05-.18-1.41-.54c-.36-.36-.54-.83-.54-1.41s.18-1.05.54-1.41c.36-.36.83-.54 1.41-.54z"
			></path>
			<g opacity="0.5">
				<rect width="316" height="192.262" x="311" fill="#DFE1E6" rx="4"></rect>
				<rect
					width="80.771"
					height="179.902"
					x="316.668"
					y="5.495"
					fill="#fff"
					rx="4"
				></rect>
				<rect
					width="218.224"
					height="32.959"
					x="403.108"
					y="5.493"
					fill="#fff"
					rx="4"
				></rect>
				<rect
					width="216.807"
					height="141.45"
					x="404.525"
					y="43.946"
					fill="#fff"
					rx="4"
				></rect>
				<rect
					width="58.099"
					height="10.986"
					x="556.148"
					y="16.48"
					fill="#5F85EB"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="10.986"
					x="413.025"
					y="16.479"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="4.12"
					x="556.148"
					y="52.186"
					fill="#3C67F0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="72.785"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="127.717"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="86.519"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="141.45"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="79.652"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="134.584"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="93.385"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="148.317"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="100.251"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="155.183"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="72.784"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="127.716"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="86.517"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="141.449"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="79.651"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="134.583"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="93.384"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="148.316"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="113.984"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="168.916"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="107.118"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="162.05"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="120.851"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="556.148"
					y="175.783"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="4.12"
					x="413.025"
					y="52.185"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="100.25"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="155.182"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="113.983"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="168.915"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="107.117"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="162.048"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="120.85"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="413.025"
					y="175.782"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<path
					fill="#EBECF0"
					d="M322.334 164.795H391.769V178.528H322.334z"
				></path>
				<path
					fill="#EBECF0"
					d="M322.334 148.316H391.769V162.049H322.334z"
				></path>
				<path
					fill="#EBECF0"
					d="M322.334 131.836H391.769V145.56900000000002H322.334z"
				></path>
				<path
					fill="#EBECF0"
					d="M322.334 115.357H391.769V129.09H322.334z"
				></path>
				<path fill="#EBECF0" d="M322.334 98.877H391.769V112.61H322.334z"></path>
			</g>
			<circle cx="533.517" cy="96.845" r="43.845" fill="#D2F9E1"></circle>
			<path fill="#DFE1E6" d="M533.4 71.566H542.973V85.803H533.4z"></path>
			<path fill="#505F79" d="M516.27 71.82H518.433V124.816H516.27z"></path>
			<path
				fill="#505F79"
				d="M513.026 74.267H514.4989999999999V105.196H513.026z"
				transform="rotate(-90 513.026 74.267)"
			></path>
			<path
				fill="#C1C7D0"
				d="M513.762 127.27a2.455 2.455 0 012.455-2.454h1.964a2.455 2.455 0 012.455 2.454h-6.874z"
			></path>
			<path fill="#C1C7D0" d="M515.481 71.812H518.918V75.249H515.481z"></path>
			<path fill="#DFE1E6" d="M532.173 66.412H544.201V70.34H532.173z"></path>
			<path
				fill="#DFE1E6"
				d="M532.173 70.34H539.046V71.56700000000001H532.173z"
			></path>
			<path
				fill="#C1C7D0"
				d="M537.315 70.34H544.1880000000001V71.56700000000001H537.315z"
			></path>
			<path
				fill="#DFE1E6"
				d="M532.839 84.419a2 2 0 011.879-1.315h6.938a2 2 0 011.879 1.315l9.996 27.42a2 2 0 01-1.879 2.685h-26.931a2 2 0 01-1.879-2.685l9.997-27.42z"
			></path>
			<path
				fill="#63F19C"
				d="M533.484 86.236a2 2 0 011.88-1.319h5.75a2 2 0 011.881 1.319l8.614 23.758a2 2 0 01-1.88 2.682H526.75a2 2 0 01-1.88-2.682l8.614-23.758z"
			></path>
			<rect
				width="27.77"
				height="27.77"
				x="476"
				y="91.868"
				fill="#F9B4B4"
				rx="4"
			></rect>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeWidth="5"
				d="M482.171 98.039l15.428 15.427M497.599 98.039l-15.428 15.427"
			></path>
			<circle cx="427.272" cy="98.845" r="43.845" fill="#A6BFF7"></circle>
			<path fill="#DFE1E6" d="M426.911 74.028H436.484V88.265H426.911z"></path>
			<path
				fill="#505F79"
				d="M409.782 74.282H411.945V127.27799999999999H409.782z"
			></path>
			<path
				fill="#505F79"
				d="M406.537 76.729H408.01V107.658H406.537z"
				transform="rotate(-90 406.537 76.729)"
			></path>
			<path
				fill="#C1C7D0"
				d="M407.274 129.732a2.455 2.455 0 012.455-2.455h1.964a2.455 2.455 0 012.454 2.455h-6.873z"
			></path>
			<path
				fill="#C1C7D0"
				d="M408.992 74.274H412.42900000000003V77.711H408.992z"
			></path>
			<path
				fill="#DFE1E6"
				d="M425.684 68.874H437.71200000000005V72.80199999999999H425.684z"
			></path>
			<path
				fill="#DFE1E6"
				d="M425.684 72.802H432.557V74.02900000000001H425.684z"
			></path>
			<path
				fill="#C1C7D0"
				d="M430.826 72.802H437.699V74.02900000000001H430.826z"
			></path>
			<path
				fill="#DFE1E6"
				d="M426.35 86.88a2 2 0 011.879-1.314h6.938a2 2 0 011.879 1.314l9.997 27.421a2 2 0 01-1.879 2.685h-26.932a2 2 0 01-1.879-2.685l9.997-27.42z"
			></path>
			<path
				fill="#3C67F0"
				d="M426.995 88.698a2 2 0 011.88-1.319h5.751a2 2 0 011.88 1.319l8.614 23.758a2 2 0 01-1.88 2.682h-22.979a2 2 0 01-1.88-2.682l8.614-23.758z"
			></path>
			<rect
				width="27.77"
				height="27.77"
				x="368"
				y="94.34"
				fill="#9CF1BE"
				rx="4"
			></rect>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeWidth="3"
				d="M373.4 110.796l7.149 5.914 10.592-16.97"
			></path>
			<path
				fill="#C1C7D0"
				d="M525.5 127.5c.4 2.8 4.833 9.5 7 12.5 3.6 2.8 4.5-2.167 4.5-5-1.5-1.167-4.7-3.9-5.5-5.5-1-2-3-6-2.5-7s-.5-3.5-.5-2.5-1.5 2-1 1.5-1-2.5-3-2.5-2 .5-2 3 2.5 2 3 5.5z"
			></path>
			<path
				fill="#1D1D1D"
				d="M555.296 234.915v-4.612h-3.527l-1.899 2.984h-2.984l-1.628 1.628h6.511l.543-1.085 1.085 1.085h1.899zM581.341 232.744l-1.356-3.527h-2.713l-.543 5.426h-2.713L571.845 236h6.512l2.984-3.256z"
			></path>
			<path
				fill="#C1C7D0"
				d="M563.164 139.687l.271-2.713-5.155-1.899-.542 4.884c.181.542.651 1.682 1.085 1.899.434.217 3.075-1.357 4.341-2.171z"
			></path>
			<path
				fill="#C1C7D0"
				d="M557.195 134.261c-.868-1.519 3.256-5.878 5.426-7.868 1.628-1.085 5.697 2.985 5.969 3.799.271.814-.814 3.255-3.799 6.24-2.984 2.984-6.511-.272-7.596-2.171z"
			></path>
			<path
				fill="#1D1D1D"
				d="M566.962 125.58c-1.085-1.086-3.527-.995-4.612-.814-1.736 1.302.543 1.356 4.612 4.34.271.543-2.442 4.884-1.628 3.799.434-.434 1.086-.362 1.357-.272 2.17.543-3.798 2.985-2.713 3.527.868.434 1.447.181 1.628 0l3.255-3.798c1.628-1.899-.542-5.426-1.899-6.782z"
			></path>
			<path
				fill="#1B55CE"
				d="M558.871 141.315c.868.651 3.255-.995 4.34-1.899l6.512-.271c.181 0 .705.217 1.356 1.085.814 1.085-.814 10.581-1.356 12.209-.543 1.627-4.884 16.549-5.426 16.82-.434.217-8.863.091-13.023 0-.452-6.42-1.356-19.859-1.356-22.247 0-2.387 2.17-6.059 3.255-7.596h4.612c0 .362.217 1.248 1.086 1.899z"
			></path>
			<path
				fill="#F3F6FA"
				d="M547.699 194.491c2.171.434 3.799-2.171 4.341-3.527.271-3.708.814-11.938.814-15.193 0-4.07-1.899-10.039-1.628-15.193.217-4.124 4.432-15.826 6.512-21.162-3.039-.217-6.693-.271-8.14-.271-.994 3.255-3.309 12.859-4.612 25.231-1.628 15.464 0 29.572 2.713 30.115z"
			></path>
			<path
				fill="#DEE9FD"
				d="M553.668 145.113c0 1.303-1.266 8.682-1.899 12.209l5.969-18.177h-3.799c-.09 1.447-.271 4.666-.271 5.968z"
			></path>
			<path
				fill="#253858"
				d="M550.955 230.845l1.085-61.586h12.48c-1.176 7.326-3.635 22.084-4.069 22.519-.543.542-2.442 8.41-1.086 14.921 1.086 5.209-1.899 18.268-3.527 24.146h-4.883z"
			></path>
			<path
				fill="#505F79"
				d="M577 230.574l3.527-1.357c.272-.633.651-3.798 0-11.394-.814-9.496-8.41-13.566-8.953-19.806-.434-4.991-5.064-21.432-7.325-29.029h-11.937c3.527 9.586 11.34 29.789 14.379 33.913 3.038 4.124 8.139 20.167 10.309 27.673z"
			></path>
			<path
				fill="#F3F6FA"
				d="M560.618 192.416c-2.986-2.409-2.689-26.426-2.067-35.908l2.713-7.868 4.884-9.495c5.599-.335 5.445-.046 6.511 1.627 1.067 1.674-1.885 22.301-1.085 26.317.8 4.016 4.508 27.335 2.375 25.327-2.133-2.007-9.598 3.012-13.331 0z"
			></path>
			<path
				fill="#DEE9FD"
				d="M558.552 156.779c-.651-1.302 2.984-12.299 4.883-17.634h2.984c-1.447 2.713-5.046 10.038-7.867 17.634z"
			></path>
			<path
				fill="#C1C7D0"
				d="M571.257 181.581c1.085-.435 4.974-5.427 6.782-7.868 3.256-4.558.091-3.889-1.899-2.985-2.623 2.894-7.922 9.008-8.139 10.31l3.256.543z"
			></path>
			<path
				fill="#F3F6FA"
				d="M584.325 162.205c2.605-5.643-6.33-16.278-11.123-20.89-8.139-3.527-6.24 4.341-4.612 7.868 1.628 3.527 9.224 11.937 9.224 14.379s-2.713 7.596-1.085 8.41c1.628.814.814 2.985 2.442 1.628 1.627-1.356 1.899-4.341 5.154-11.395z"
			></path>
			<path
				fill="#FAFBFC"
				d="M564.249 180.925c1.736-.651 5.969.091 7.868.543 0 2.17-.217 6.728-1.085 7.596-1.085 1.086-6.783 1.086-7.597 0-.814-1.085-1.356-7.325.814-8.139z"
			></path>
			<path
				fill="#F3F6FA"
				d="M547.428 148.64l2.713-9.224c-7.054-.543-7.325.543-8.41-1.085-.853-1.28-3.522-3.886-4.816-5.092.007-.162-.028-.142-.068-.063l.068.063c-.01.211-.094.731-.339 1.836-.434 1.954-3.617 4.431-5.155 5.426.271.905 1.085 3.147 2.171 4.884 1.085 1.736 9.676 2.894 13.836 3.255z"
			></path>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M379.334 139.696c-1.615-1.102-2.919-2.634-3.306-4.638-1.118-5.774 7.175-6.66 9.489-4.215 2.315 2.445 4.075 9.749 2.211 10.797-.743.417-2.577.373-4.584-.197l3.126 7.323-6.411 1.718-.525-10.788z"
				clipRule="evenodd"
			></path>
			<path
				fill="#191847"
				fillRule="evenodd"
				d="M382.15 128.408c-.947-.031-2.243.22-2.761.425a4.712 4.712 0 00-.293-2.4c-.3-.736-.817-1.293-1.317-1.887-.23-.274-.48-.541-.791-.715a1.99 1.99 0 00-1.035-.239 2.53 2.53 0 00-1.043.278c-.143.073-.261.178-.4.256-.141.08-.241.005-.377-.06-.327-.158-.748-.166-1.096-.116a2.667 2.667 0 00-1.095.438 2.295 2.295 0 00-.782.851 1.637 1.637 0 00-.168.504c-.009.073-.004.112-.063.154-.055.038-.125.058-.182.096a2.518 2.518 0 00-1.067 1.638c-.071.391.015 1.023.22 1.703.205.679 1.308 2.3 2.067 2.796.677.442 1.546.621 2.33.591-.342.688-.482 1.47-.534 2.241a9.81 9.81 0 00.152 2.507c.15.788.363 1.605.748 2.312.36.661.881 1.142 1.589 1.347.325.093.659.139.992.174.329.034.657.065.986.045.623-.037 1.298-.24 1.628-.828.119-.212.243-.471.308-.709.023-.085.036-.14-.036-.198-.085-.07-.204-.109-.303-.155a2.357 2.357 0 01-1.219-1.293c-.238-.601-.285-1.446.251-1.88.661-.535 1.591-.373 2.354-.195.018-1.068.361-2.118 1.153-2.817.875-.775 2.018-1.144 2.941-1.855.24-.185.482-.386.619-.669.058-.118.122-.269.121-.405 0-.178-.146-.288-.265-.396-.628-.569-2.685-1.509-3.632-1.539z"
				clipRule="evenodd"
			></path>
			<path
				fill="#305CBD"
				fillRule="evenodd"
				d="M381.321 172.811l10.027 35.052 7.316 25.835H404l-7.774-60.887h-14.905z"
				clipRule="evenodd"
			></path>
			<path
				fill="#1753E2"
				fillRule="evenodd"
				d="M378.64 172.811c-.232 18.066-1.023 27.92-1.267 29.561-.243 1.641-3.925 12.083-11.046 31.326h5.528c9.065-18.46 14.125-28.902 15.181-31.326 1.055-2.424 4.313-12.278 9.22-29.561H378.64z"
				clipRule="evenodd"
			></path>
			<path
				fill="#0B1730"
				fillRule="evenodd"
				d="M365.833 238.699l.276-5.808h5.808c2.755 1.936 6.35 3.504 10.787 4.702v1.106h-10.51l-3.595-.553v.553h-2.766zM397.916 238.699l.276-5.808H404c2.754 1.936 6.35 3.504 10.786 4.702v1.106h-10.509l-3.596-.553v.553h-2.765z"
				clipRule="evenodd"
			></path>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M403.865 165.509l9.445 5.486c2.446.388 4.517.974 6.214 1.757.412.302.941.946-.636.991-1.576.046-3.233.164-3.393.718-.16.553.853 1.088.492 1.851-.241.508-1.74-.545-4.498-3.158l-9.064-2.669 1.44-4.976zm-37.76 3.563l4.947-.153c-3.207 12.093-4.941 18.495-5.201 19.206-.586 1.6.85 3.955 1.473 5.225-1.856.907-1.762-2.209-4.102-1.024-2.136 1.082-3.724 2.962-6.285 1.511-.314-.178-.677-.879.121-1.465 1.985-1.46 4.833-3.993 5.201-4.807.503-1.109 1.785-7.274 3.846-18.493z"
				clipRule="evenodd"
			></path>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M382.817 145.948l2.816-1.511c5.832 9.365 13.599 18.051 25.156 24.507l-1.742 4.73c-9.798-3.639-18.917-6.2-23.705-13.409-1.607-2.42-2.066-11.166-2.525-14.317z"
				clipRule="evenodd"
			></path>
			<path
				fill="#253858"
				fillRule="evenodd"
				d="M377.552 176.126c8.722-.606 15.462-1.794 20.22-3.565.589-.219.507-1.251.103-1.89-6.291-9.951-10.958-18.778-12.272-26.229-.736.548-2.138.858-4.206.93-4.07 8.634-5.01 17.883-3.845 30.754z"
				clipRule="evenodd"
			></path>
			<path
				fill="#DFE1E6"
				fillRule="evenodd"
				d="M379.935 144.966l2.261-.398c1.069 2.987 10.084 44.405 17.614 57.146l-27.729 4.889c-1.602-12.605.492-28.456 3.08-41.868-3.565 4.617-6.112 11.824-7.639 21.62l-5.428-.411c.942-17.24 6.736-30.811 17.381-40.713l.047-.192.2-.035.196-.18.017.142z"
				clipRule="evenodd"
			></path>
			<g opacity="0.5">
				<rect
					width="316"
					height="192.262"
					x="1189"
					fill="#DFE1E6"
					rx="4"
				></rect>
				<rect
					width="80.771"
					height="179.902"
					x="1194.67"
					y="5.495"
					fill="#fff"
					rx="4"
				></rect>
				<rect
					width="218.224"
					height="32.959"
					x="1281.11"
					y="5.493"
					fill="#fff"
					rx="4"
				></rect>
				<rect
					width="216.807"
					height="141.45"
					x="1282.52"
					y="43.946"
					fill="#fff"
					rx="4"
				></rect>
				<rect
					width="58.099"
					height="10.986"
					x="1434.15"
					y="16.48"
					fill="#5F85EB"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="10.986"
					x="1291.03"
					y="16.479"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="4.12"
					x="1434.15"
					y="52.186"
					fill="#3C67F0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="72.785"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="127.717"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="86.519"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="141.45"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="79.652"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="134.584"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="93.385"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="148.317"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="100.251"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="155.183"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="72.784"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="127.716"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="86.517"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="141.449"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="79.651"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="134.583"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="93.384"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="148.316"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="113.984"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="168.916"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="107.118"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="162.05"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="120.851"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="1434.15"
					y="175.783"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="4.12"
					x="1291.03"
					y="52.185"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="100.25"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="155.182"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="113.983"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="168.915"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="107.117"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="162.048"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="120.85"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="1291.03"
					y="175.782"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<path
					fill="#EBECF0"
					d="M1200.33 164.795H1269.7649999999999V178.528H1200.33z"
				></path>
				<path
					fill="#EBECF0"
					d="M1200.33 148.316H1269.7649999999999V162.049H1200.33z"
				></path>
				<path
					fill="#EBECF0"
					d="M1200.33 131.836H1269.7649999999999V145.56900000000002H1200.33z"
				></path>
				<path
					fill="#EBECF0"
					d="M1200.33 115.357H1269.7649999999999V129.09H1200.33z"
				></path>
				<path
					fill="#EBECF0"
					d="M1200.33 98.877H1269.7649999999999V112.61H1200.33z"
				></path>
			</g>
			<circle cx="1411.52" cy="96.845" r="43.845" fill="#D2F9E1"></circle>
			<path
				fill="#DFE1E6"
				d="M1411.4 71.566H1420.9730000000002V85.803H1411.4z"
			></path>
			<path fill="#505F79" d="M1394.27 71.82H1396.433V124.816H1394.27z"></path>
			<path
				fill="#505F79"
				d="M1391.03 74.267H1392.503V105.196H1391.03z"
				transform="rotate(-90 1391.03 74.267)"
			></path>
			<path
				fill="#C1C7D0"
				d="M1391.76 127.27c0-1.355 1.1-2.454 2.46-2.454h1.96c1.36 0 2.46 1.099 2.46 2.454h-6.88z"
			></path>
			<path fill="#C1C7D0" d="M1393.48 71.812H1396.917V75.249H1393.48z"></path>
			<path fill="#DFE1E6" d="M1410.17 66.412H1422.198V70.34H1410.17z"></path>
			<path
				fill="#DFE1E6"
				d="M1410.17 70.34H1417.0430000000001V71.56700000000001H1410.17z"
			></path>
			<path
				fill="#C1C7D0"
				d="M1415.31 70.34H1422.183V71.56700000000001H1415.31z"
			></path>
			<path
				fill="#DFE1E6"
				d="M1410.84 84.419a2.004 2.004 0 011.88-1.315h6.94c.84 0 1.59.525 1.87 1.315l10 27.42c.48 1.304-.49 2.685-1.88 2.685h-26.93c-1.39 0-2.35-1.381-1.88-2.685l10-27.42z"
			></path>
			<path
				fill="#63F19C"
				d="M1411.48 86.236a2.004 2.004 0 011.88-1.319h5.75c.85 0 1.6.528 1.88 1.319l8.62 23.758a2 2 0 01-1.88 2.682h-22.98a2 2 0 01-1.88-2.682l8.61-23.758z"
			></path>
			<rect
				width="27.77"
				height="27.77"
				x="1354"
				y="91.868"
				fill="#F9B4B4"
				rx="4"
			></rect>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeWidth="5"
				d="M1360.17 98.039l15.43 15.427M1375.6 98.039l-15.43 15.427"
			></path>
			<circle cx="1305.27" cy="98.845" r="43.845" fill="#A6BFF7"></circle>
			<path
				fill="#DFE1E6"
				d="M1304.91 74.028H1314.4830000000002V88.265H1304.91z"
			></path>
			<path
				fill="#505F79"
				d="M1287.78 74.282H1289.943V127.27799999999999H1287.78z"
			></path>
			<path
				fill="#505F79"
				d="M1284.54 76.729H1286.013V107.658H1284.54z"
				transform="rotate(-90 1284.54 76.729)"
			></path>
			<path
				fill="#C1C7D0"
				d="M1285.27 129.732c0-1.356 1.1-2.455 2.46-2.455h1.96c1.36 0 2.46 1.099 2.46 2.455h-6.88z"
			></path>
			<path fill="#C1C7D0" d="M1286.99 74.274H1290.427V77.711H1286.99z"></path>
			<path
				fill="#DFE1E6"
				d="M1303.68 68.874H1315.708V72.80199999999999H1303.68z"
			></path>
			<path
				fill="#DFE1E6"
				d="M1303.68 72.802H1310.553V74.02900000000001H1303.68z"
			></path>
			<path
				fill="#C1C7D0"
				d="M1308.83 72.802H1315.703V74.02900000000001H1308.83z"
			></path>
			<path
				fill="#DFE1E6"
				d="M1304.35 86.88a2.004 2.004 0 011.88-1.314h6.94c.84 0 1.59.525 1.88 1.314l9.99 27.421c.48 1.304-.49 2.685-1.88 2.685h-26.93c-1.39 0-2.35-1.381-1.88-2.685l10-27.42z"
			></path>
			<path
				fill="#3C67F0"
				d="M1305 88.698a1.985 1.985 0 011.88-1.319h5.75c.84 0 1.59.527 1.88 1.319l8.61 23.758a2 2 0 01-1.88 2.682h-22.98a2 2 0 01-1.88-2.682l8.62-23.758z"
			></path>
			<rect
				width="27.77"
				height="27.77"
				x="1246"
				y="94.34"
				fill="#9CF1BE"
				rx="4"
			></rect>
			<path
				stroke="#fff"
				strokeLinecap="round"
				strokeWidth="3"
				d="M1251.4 110.796l7.15 5.914 10.59-16.97"
			></path>
			<path
				fill="#C1C7D0"
				d="M1403.5 127.5c.4 2.8 4.83 9.5 7 12.5 3.6 2.8 4.5-2.167 4.5-5-1.5-1.167-4.7-3.9-5.5-5.5-1-2-3-6-2.5-7s-.5-3.5-.5-2.5-1.5 2-1 1.5-1-2.5-3-2.5-2 .5-2 3 2.5 2 3 5.5z"
			></path>
			<path
				fill="#1D1D1D"
				d="M1433.3 234.915v-4.612h-3.53l-1.9 2.984h-2.98l-1.63 1.628h6.51l.54-1.085 1.09 1.085h1.9z"
			></path>
			<path
				fill="#C1C7D0"
				d="M1441.16 139.687l.28-2.713-5.16-1.899-.54 4.884c.18.542.65 1.682 1.08 1.899.44.217 3.08-1.357 4.34-2.171z"
			></path>
			<path
				fill="#C1C7D0"
				d="M1435.19 134.261c-.86-1.519 3.26-5.878 5.43-7.868 1.63-1.085 5.7 2.985 5.97 3.799.27.814-.81 3.255-3.8 6.24-2.98 2.984-6.51-.272-7.6-2.171z"
			></path>
			<path
				fill="#1D1D1D"
				d="M1444.96 125.58c-1.08-1.086-3.52-.995-4.61-.814-1.74 1.302.54 1.356 4.61 4.34.27.543-2.44 4.884-1.63 3.799.44-.434 1.09-.362 1.36-.272 2.17.543-3.8 2.985-2.71 3.527.87.434 1.44.181 1.63 0l3.25-3.798c1.63-1.899-.54-5.426-1.9-6.782z"
			></path>
			<path
				fill="#1B55CE"
				d="M1436.87 141.315c.87.651 3.26-.995 4.34-1.899l6.51-.271c.18 0 .71.217 1.36 1.085.81 1.085-.81 10.581-1.36 12.209-.54 1.627-4.88 16.549-5.42 16.82-.44.217-8.87.091-13.03 0-.45-6.42-1.35-19.859-1.35-22.247 0-2.387 2.17-6.059 3.25-7.596h4.62c0 .362.21 1.248 1.08 1.899z"
			></path>
			<path
				fill="#F3F6FA"
				d="M1425.7 194.491c2.17.434 3.8-2.171 4.34-3.527.27-3.708.81-11.938.81-15.193 0-4.07-1.9-10.039-1.62-15.193.21-4.124 4.43-15.826 6.51-21.162-3.04-.217-6.69-.271-8.14-.271-1 3.255-3.31 12.859-4.61 25.231-1.63 15.464 0 29.572 2.71 30.115z"
			></path>
			<path
				fill="#DEE9FD"
				d="M1431.67 145.113c0 1.303-1.27 8.682-1.9 12.209l5.97-18.177h-3.8c-.09 1.447-.27 4.666-.27 5.968z"
			></path>
			<path
				fill="#253858"
				d="M1428.95 230.845l1.09-61.586h12.48c-1.18 7.326-3.64 22.084-4.07 22.519-.54.542-2.44 8.41-1.08 14.921 1.08 5.209-1.9 18.268-3.53 24.146h-4.89z"
			></path>
			<path
				fill="#505F79"
				d="M1455 230.574l3.53-1.357c.27-.633.65-3.798 0-11.394-.82-9.496-8.41-13.566-8.96-19.806-.43-4.991-5.06-21.432-7.32-29.029h-11.94c3.53 9.586 11.34 29.789 14.38 33.913 3.04 4.124 8.14 20.167 10.31 27.673z"
			></path>
			<path
				fill="#F3F6FA"
				d="M1438.62 192.416c-2.99-2.409-2.69-26.426-2.07-35.908l2.71-7.868 4.89-9.495c5.6-.335 5.44-.046 6.51 1.627 1.07 1.674-1.89 22.301-1.09 26.317.8 4.016 4.51 27.335 2.38 25.327-2.13-2.007-9.6 3.012-13.33 0z"
			></path>
			<path
				fill="#DEE9FD"
				d="M1436.55 156.779c-.65-1.302 2.99-12.299 4.89-17.634h2.98c-1.45 2.713-5.05 10.038-7.87 17.634z"
			></path>
			<path
				fill="#F3F6FA"
				d="M1425.43 148.64l2.71-9.224c-7.05-.543-7.32.543-8.41-1.085-.85-1.28-3.52-3.886-4.82-5.092.01-.162-.02-.142-.06-.063l.06.063c-.01.211-.09.731-.33 1.836-.44 1.954-3.62 4.431-5.16 5.426.27.905 1.09 3.147 2.17 4.884 1.09 1.736 9.68 2.894 13.84 3.255z"
			></path>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M1252.33 140.696c-1.61-1.102-2.91-2.634-3.3-4.638-1.12-5.774 7.17-6.66 9.49-4.215 2.31 2.445 4.07 9.749 2.21 10.797-.75.417-2.58.373-4.59-.197l3.13 7.323-6.41 1.718-.53-10.788z"
				clipRule="evenodd"
			></path>
			<path
				fill="#191847"
				fillRule="evenodd"
				d="M1255.15 129.408c-.95-.031-2.24.22-2.76.425.11-.805.01-1.642-.29-2.4-.3-.736-.82-1.293-1.32-1.887-.23-.274-.48-.541-.79-.715a2.001 2.001 0 00-1.04-.239c-.36.013-.72.115-1.04.278-.14.073-.26.178-.4.256-.14.08-.24.005-.38-.06-.32-.158-.75-.166-1.09-.116a2.71 2.71 0 00-1.1.438c-.32.214-.6.499-.78.851-.08.156-.14.327-.17.504-.01.073 0 .112-.06.154-.06.038-.13.058-.18.096-.55.365-.95.967-1.07 1.638-.07.391.01 1.023.22 1.703.2.679 1.31 2.3 2.07 2.796.67.442 1.54.621 2.33.591-.34.688-.49 1.47-.54 2.241-.05.838 0 1.679.15 2.507.15.788.37 1.605.75 2.312.36.661.88 1.142 1.59 1.347.33.093.66.139.99.174.33.034.66.065.99.045.62-.037 1.3-.24 1.63-.828.12-.212.24-.471.31-.709.02-.085.03-.14-.04-.198-.09-.07-.2-.109-.3-.155a2.346 2.346 0 01-1.22-1.293c-.24-.601-.29-1.446.25-1.88.66-.535 1.59-.373 2.35-.195.02-1.068.36-2.118 1.16-2.817.87-.775 2.01-1.144 2.94-1.855.24-.185.48-.386.62-.669.05-.118.12-.269.12-.405 0-.178-.15-.288-.27-.396-.63-.569-2.68-1.509-3.63-1.539z"
				clipRule="evenodd"
			></path>
			<path
				fill="#305CBD"
				fillRule="evenodd"
				d="M1254.32 173.811l10.03 35.052 7.31 25.835h5.34l-7.77-60.887h-14.91z"
				clipRule="evenodd"
			></path>
			<path
				fill="#1753E2"
				fillRule="evenodd"
				d="M1251.64 173.811c-.23 18.066-1.02 27.92-1.27 29.561-.24 1.641-3.92 12.083-11.04 31.326h5.52c9.07-18.46 14.13-28.902 15.19-31.326 1.05-2.424 4.31-12.278 9.22-29.561h-17.62z"
				clipRule="evenodd"
			></path>
			<path
				fill="#0B1730"
				fillRule="evenodd"
				d="M1238.83 239.699l.28-5.808h5.81c2.75 1.936 6.35 3.504 10.78 4.702v1.106h-10.51l-3.59-.553v.553h-2.77zM1270.92 239.699l.27-5.808h5.81c2.75 1.936 6.35 3.504 10.79 4.702v1.106h-10.51l-3.6-.553v.553h-2.76z"
				clipRule="evenodd"
			></path>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M1276.86 166.509l9.45 5.486c2.45.388 4.52.974 6.21 1.757.42.302.94.946-.63.991-1.58.046-3.23.164-3.39.718-.17.553.85 1.088.49 1.851-.24.508-1.74-.545-4.5-3.158l-9.06-2.669 1.43-4.976zm-37.76 3.563l4.95-.153c-3.21 12.093-4.94 18.495-5.2 19.206-.58 1.6.85 3.955 1.47 5.225-1.85.907-1.76-2.209-4.1-1.024-2.13 1.082-3.72 2.962-6.28 1.511-.32-.178-.68-.879.12-1.465 1.98-1.46 4.83-3.993 5.2-4.807.5-1.109 1.78-7.274 3.84-18.493z"
				clipRule="evenodd"
			></path>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M1255.82 146.948l2.81-1.511c5.84 9.365 13.6 18.051 25.16 24.507l-1.74 4.73c-9.8-3.639-18.92-6.2-23.71-13.409-1.61-2.42-2.06-11.166-2.52-14.317z"
				clipRule="evenodd"
			></path>
			<path
				fill="#253858"
				fillRule="evenodd"
				d="M1250.55 177.126c8.72-.606 15.46-1.794 20.22-3.565.59-.219.51-1.251.1-1.89-6.29-9.951-10.95-18.778-12.27-26.229-.73.548-2.13.858-4.2.93-4.07 8.634-5.01 17.883-3.85 30.754z"
				clipRule="evenodd"
			></path>
			<path
				fill="#DFE1E6"
				fillRule="evenodd"
				d="M1252.94 145.966l2.26-.398c1.07 2.987 10.08 44.405 17.61 57.146l-27.73 4.889c-1.6-12.605.49-28.456 3.08-41.868-3.56 4.617-6.11 11.824-7.64 21.62l-5.43-.411c.95-17.24 6.74-30.811 17.39-40.713.01-.064.03-.128.04-.192l.2-.035c.07-.06.13-.12.2-.18l.02.142z"
				clipRule="evenodd"
			></path>
			<g opacity="0.5">
				<rect
					width="316"
					height="192.262"
					x="-41"
					y="5"
					fill="#DFE1E6"
					rx="4"
				></rect>
				<rect
					width="80.771"
					height="179.902"
					x="-35.332"
					y="10.495"
					fill="#fff"
					rx="4"
				></rect>
				<rect
					width="218.224"
					height="32.959"
					x="51.108"
					y="10.493"
					fill="#fff"
					rx="4"
				></rect>
				<rect
					width="216.807"
					height="141.45"
					x="52.525"
					y="48.946"
					fill="#fff"
					rx="4"
				></rect>
				<rect
					width="58.099"
					height="10.986"
					x="204.148"
					y="21.48"
					fill="#5F85EB"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="10.986"
					x="61.025"
					y="21.479"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="4.12"
					x="204.148"
					y="57.186"
					fill="#3C67F0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="77.785"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="132.717"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="91.519"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="146.45"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="84.652"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="139.584"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="98.385"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="153.317"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="105.251"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="160.183"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="77.784"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="132.716"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="91.517"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="146.449"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="84.651"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="139.583"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="98.384"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="153.316"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="118.984"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="173.916"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="112.118"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="167.05"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="125.851"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="58.099"
					height="2.747"
					x="204.148"
					y="180.783"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="4.12"
					x="61.025"
					y="57.185"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="105.25"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="160.182"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="118.983"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="173.915"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="112.117"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="167.048"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="125.85"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<rect
					width="127.534"
					height="2.747"
					x="61.025"
					y="180.782"
					fill="#C1C7D0"
					rx="1"
				></rect>
				<path
					fill="#EBECF0"
					d="M-29.666 169.795H39.769000000000005V183.528H-29.666z"
				></path>
				<path
					fill="#EBECF0"
					d="M-29.666 153.316H39.769000000000005V167.049H-29.666z"
				></path>
				<path
					fill="#EBECF0"
					d="M-29.666 136.836H39.769000000000005V150.56900000000002H-29.666z"
				></path>
				<path
					fill="#EBECF0"
					d="M-29.666 120.357H39.769000000000005V134.09H-29.666z"
				></path>
				<path
					fill="#EBECF0"
					d="M-29.666 103.877H39.769000000000005V117.61H-29.666z"
				></path>
			</g>
			<path
				fill="#C1C7D0"
				d="M209.486 121.151c0 1.538.512 1.922.768 1.922-.615 1.538-3.075 1.666-4.228 1.538-.128-.513-.615-2.076-1.538-4.229-1.153-2.691 0-2.307.769-3.844.769-1.538 2.306-1.538 2.691-1.538.384 0 1.153.769 1.538 2.307.384 1.537 0 1.922 0 3.844z"
			></path>
			<path
				fill="#3C67F0"
				d="M235.246 144.986c2.46 0 6.151.512 7.688.769 7.689 2.306 8.074 6.535 7.305 8.073-.769 1.537-1.923 17.684-3.076 18.837-1.153 1.153-15.762 1.153-19.222 0-3.46-1.153 1.154-17.3 1.538-18.068.385-.769-15.377-5.767-18.068-8.074-2.153-1.845-4.742-15.633-5.767-22.297.769-.897 2.768-2.383 4.613-1.153 2.307 1.538 6.151 17.684 8.842 18.837 2.691 1.153 13.071 3.076 16.147 3.076z"
			></path>
			<path
				fill="#C1C7D0"
				d="M243.322 145.755l.769-3.075c-.641-3.973-2.384-11.918-4.229-11.918-2.307 0-4.229 3.076-5.382 4.614-1.154 1.537-.385 4.613-.385 5.766 0 .923 2.307 1.922 3.46 2.307v1.922l5.767.384z"
			></path>
			<path
				fill="#253858"
				d="M242.489 137.924c-.856-3.465-4.283-6.234-5.71-7.547 13.073-1.922 12.838 6.012 12.301 8.073-.537 2.061-3.262 4.329-5.521 5.379 0-.525-.214-2.441-1.07-5.905z"
			></path>
			<path
				fill="#253858"
				d="M241.01 136.528c.923-.923-.641-3.716-1.538-4.998 1.153 1.154 3.537 3.768 3.844 4.998.385 1.538-1.538 5.382-2.306 6.151-.768.769-4.614.769-6.151.384-1.231-.307-1.282-2.178-1.154-3.075.385.256 1.23.769 1.538.769.384 0 1.153-1.154.769-1.922-.308-.616-1.666-.769-2.307-.769v-.769c.513-.128 1.692-.385 2.307-.385s2.05 1.026 2.691 1.538a17.127 17.127 0 002.307-1.922z"
			></path>
			<path
				fill="#1753E2"
				d="M251.007 169.974c.615-1.538-.256-11.917-.769-16.915l-3.075 19.606v2.307c1.025-1.025 3.229-3.46 3.844-4.998z"
			></path>
			<path
				fill="#253858"
				d="M244.913 205.027c1.016-4.029 2.682-22.78 3.387-31.652-4.516 3.477-13.463 11.582-13.125 16.186.339 4.604.565 36.088.847 48.916 1.694.24 5.335 1.151 6.351 0 1.27-1.438 1.27-28.415 2.54-33.45z"
			></path>
			<path
				fill="#505F79"
				d="M246.349 179.454c2.835-3.159 1.968-5.863 1.181-6.821-4.462.479-14.49.431-20.474-.718-4.725 9.191-8.662 24.412-13.781 67.131h5.119c7.087-15.795 7.087-31.95 8.268-34.463 1.181-2.512 5.906-7.538 7.087-10.051s9.056-11.129 12.6-15.078z"
			></path>
			<path
				fill="#1D1D1D"
				d="M242.462 242.076v-4.665l-5.766-.717-4.998 4.305v1.077h10.764zM218.714 241.894v-4.997l-4.997-.769-8.842 4.613v1.153h13.839z"
			></path>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M113.334 140.696c-1.615-1.102-2.919-2.634-3.306-4.638-1.118-5.774 7.175-6.66 9.489-4.215 2.315 2.445 4.075 9.749 2.211 10.797-.743.417-2.577.373-4.584-.197l3.126 7.323-6.411 1.718-.525-10.788z"
				clipRule="evenodd"
			></path>
			<path
				fill="#191847"
				fillRule="evenodd"
				d="M116.15 129.408c-.947-.031-2.243.22-2.761.425a4.712 4.712 0 00-.293-2.4c-.3-.736-.817-1.293-1.317-1.887-.23-.274-.48-.541-.791-.715a1.99 1.99 0 00-1.035-.239 2.53 2.53 0 00-1.043.278c-.143.073-.261.178-.4.256-.141.08-.241.005-.377-.06-.327-.158-.748-.166-1.096-.116a2.667 2.667 0 00-1.095.438 2.295 2.295 0 00-.782.851 1.637 1.637 0 00-.168.504c-.009.073-.004.112-.063.154-.055.038-.125.058-.182.096a2.518 2.518 0 00-1.067 1.638c-.071.391.015 1.023.22 1.703.205.679 1.308 2.3 2.067 2.796.677.442 1.546.621 2.33.591-.342.688-.482 1.47-.534 2.241a9.81 9.81 0 00.152 2.507c.15.788.363 1.605.748 2.312.36.661.881 1.142 1.589 1.347.325.093.659.139.992.174.329.034.657.065.986.045.623-.037 1.298-.24 1.628-.828.119-.212.243-.471.308-.709.023-.085.036-.14-.036-.198-.085-.07-.204-.109-.303-.155a2.357 2.357 0 01-1.219-1.293c-.238-.601-.285-1.446.251-1.88.661-.535 1.591-.373 2.354-.195.018-1.068.361-2.118 1.153-2.817.875-.775 2.018-1.144 2.941-1.855.24-.185.482-.386.619-.669.058-.118.122-.269.121-.405 0-.178-.146-.288-.265-.396-.628-.569-2.685-1.509-3.632-1.539z"
				clipRule="evenodd"
			></path>
			<path
				fill="#305CBD"
				fillRule="evenodd"
				d="M115.321 173.811l10.027 35.052 7.316 25.835H138l-7.774-60.887h-14.905z"
				clipRule="evenodd"
			></path>
			<path
				fill="#1753E2"
				fillRule="evenodd"
				d="M112.64 173.811c-.232 18.066-1.023 27.92-1.267 29.561-.243 1.641-3.925 12.083-11.046 31.326h5.528c9.065-18.46 14.125-28.902 15.181-31.326 1.055-2.424 4.313-12.278 9.22-29.561H112.64z"
				clipRule="evenodd"
			></path>
			<path
				fill="#0B1730"
				fillRule="evenodd"
				d="M99.833 239.699l.276-5.808h5.808c2.755 1.936 6.35 3.504 10.787 4.702v1.106h-10.51l-3.595-.553v.553h-2.766zM131.916 239.699l.276-5.808H138c2.754 1.936 6.35 3.504 10.786 4.702v1.106h-10.509l-3.596-.553v.553h-2.765z"
				clipRule="evenodd"
			></path>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M137.865 166.509l9.445 5.486c2.446.388 4.517.974 6.214 1.757.412.302.941.946-.636.991-1.576.046-3.233.164-3.393.718-.16.553.853 1.088.492 1.851-.241.508-1.74-.545-4.498-3.158l-9.064-2.669 1.44-4.976zm-37.76 3.563l4.947-.153c-3.207 12.093-4.941 18.495-5.201 19.206-.586 1.6.85 3.955 1.473 5.225-1.856.907-1.762-2.209-4.102-1.024-2.136 1.082-3.724 2.962-6.285 1.511-.314-.178-.677-.879.12-1.465 1.986-1.46 4.834-3.993 5.202-4.807.503-1.109 1.785-7.274 3.846-18.493z"
				clipRule="evenodd"
			></path>
			<path
				fill="#C1C7D0"
				fillRule="evenodd"
				d="M116.817 146.948l2.816-1.511c5.832 9.365 13.599 18.051 25.156 24.507l-1.742 4.73c-9.798-3.639-18.917-6.2-23.705-13.409-1.607-2.42-2.066-11.166-2.525-14.317z"
				clipRule="evenodd"
			></path>
			<path
				fill="#253858"
				fillRule="evenodd"
				d="M111.552 177.126c8.722-.606 15.462-1.794 20.22-3.565.589-.219.507-1.251.103-1.89-6.291-9.951-10.958-18.778-12.272-26.229-.736.548-2.138.858-4.206.93-4.07 8.634-5.01 17.883-3.845 30.754z"
				clipRule="evenodd"
			></path>
			<path
				fill="#DFE1E6"
				fillRule="evenodd"
				d="M113.935 145.966l2.261-.398c1.069 2.987 10.084 44.405 17.614 57.146l-27.729 4.889c-1.602-12.605.492-28.456 3.08-41.868-3.565 4.617-6.112 11.824-7.639 21.62l-5.428-.411c.942-17.24 6.736-30.811 17.381-40.713l.047-.192.2-.035.196-.18.017.142z"
				clipRule="evenodd"
			></path>
		</svg>
	)
}
